/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import ModalHeaderStyled from 'components/ModalHeader'
import ModalBodyStyled from 'components/ModalBodyStyled'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { Col, FormControl, Row, InputGroup } from 'react-bootstrap'
import FormLabel from 'react-bootstrap/FormLabel'
import FormGroup from 'react-bootstrap/FormGroup'
import FormCheck from 'react-bootstrap/FormCheck'
import * as Yup from 'yup'
import adminActions from 'store/actions/adminActions'
import ButtonStyled from 'components/ButtonStyled'
import { map, startsWith } from 'lodash'
import ModalFooterStyled from 'components/ModalFooter'
import ErrorMessageStyled from 'components/ErrorMessageStyled'
import FormikCustomInput from 'components/forms/FormikCustomInput'
import Select from 'react-select'
import { ROLE } from 'constants/consts'

const sexOptions = [
  { key: 'Female', value: 'F' },
  { key: 'Male', value: 'M' }
]

const ParticipantModal = () => {
  const dispatch = useDispatch()
  const open = useSelector(state => state.admin.participantModalOpen)
  const [childrenArray, setChildrenArray] = useState([])
  const userData = useSelector(state => state.user.data)
  const clinicians = useSelector(state => state.clinician.list)
  const [clinicianOptions, setClinicianOptions] = useState([])
  const [openParticipantInfo, setOpenParticipantInfo] = useState(false)
  const [participantInfo, setParticipantInfo] = useState({})

  useEffect(() => {
    if (
      clinicians &&
      clinicians.length !== 0
    ) {
      const list = []
      map(clinicians, clinician => {
        list.push({
          value: clinician.username,
          label: `${clinician.forename} ${clinician.surname} (${clinician.email_address})`
        })
      })
      setClinicianOptions(list)
    }
  }, [clinicians])

  const participantSchema = () => {
    let objSchema = {
      numberOfChildren: Yup.number()
        .typeError('Must be a number!')
        .required('This field is required!'),
      correctedAgeDayComponent: Yup.number()
        .typeError('Must be a number!')
        .required('This field is required!'),
      correctedAgeWeekComponent: Yup.number()
        .typeError('Must be a number!')
        .required('This field is required!'),
      notificationEmailAddress: Yup.string()
        .email()
        .typeError('Must be a valid email!')
        .required('This field is required!')
    }
    if (userData.role === ROLE.ADMIN) {
      objSchema = {
        ...objSchema,
        primaryDoctorUsername: Yup.string().required('This field is required!')
      }
    }
    return Yup.object(objSchema)
  }

  const handleClose = () => {
    dispatch(adminActions.hideParticipantModal())
  }

  const handleCloseParticipantInfo = () => {
    setOpenParticipantInfo(false)
  }

  const handleCreate = (formData, setSubmitting, resetForm) => {
    const arr = []
    map(formData, (value, key) => {
      if (startsWith(key, 'child-')) {
        arr.push(value)
        delete formData[key]
      }
    })
    const data = {
      ...formData,
      sex: arr,
      secondaryDoctorUsernames: formData.secondaryDoctorUsernames,
      primaryDoctorUsername:
        userData.role === ROLE.CLINICIAN
          ? userData.username
          : formData.primaryDoctorUsername
    }
    dispatch(adminActions.participantCreate(data)).then(participantDetails => {
      setParticipantInfo(participantDetails)
      setOpenParticipantInfo(true)

      // https://stackoverflow.com/questions/68912707/when-is-formiks-issubmitting-set-back-to-false
      // Remove all user information from the 'New Participant' modal, otherwise
      // it will persist and block the creation of a new participant.
      setSubmitting(false)
      resetForm({})
    })
    handleClose()
  }

  const handleChangeNumChildren = value => {
    const num = Number(value)
    const arr = []
    for (let i = 1; i <= num; i += 1) {
      arr.push({
        index: i,
        name: `child-${i}-sex`,
        label: `Child ${i} Sex`
      })
    }
    setChildrenArray(arr)
  }

  return (
    <>
      <Formik
        validationSchema={participantSchema}
        initialValues={{
          numberOfChildren: undefined,
          correctedAgeWeekComponent: undefined,
          correctedAgeDayComponent: undefined,
          primaryDoctorUsername: userData?.username,
          notificationEmailAddress: undefined
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true)
          handleCreate(values, setSubmitting, resetForm)
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Modal show={open} onHide={handleClose} size="lg">
            <ModalHeaderStyled closeButton>New Participant</ModalHeaderStyled>
            <ModalBodyStyled>
              <Form>
                <FormGroup as={Row} className="mt-4">
                  <FormLabel column lg={6}>
                    Corrected Age
                  </FormLabel>
                  <Col lg={6}>
                    <Field name="correctedAgeWeekComponent">
                      {({
                        field // { name, value, onChange, onBlur }
                      }) => (
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text>weeks</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl {...field} />
                        </InputGroup>
                      )}
                    </Field>
                    <Field name="correctedAgeDayComponent">
                      {({
                        field // { name, value, onChange, onBlur }
                      }) => (
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text>days</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl {...field} />
                        </InputGroup>
                      )}
                    </Field>
                  </Col>
                </FormGroup>

                {/*/!* correctedAgeWeekComponent *!/*/}
                {/*<Field name="correctedAgeWeekComponent">*/}
                {/*  {({*/}
                {/*    field // { name, value, onChange, onBlur }*/}
                {/*  }) => (*/}
                {/*    <>*/}
                {/*      <FormGroup as={Row} className="mt-4">*/}
                {/*        <FormLabel column lg={6}>*/}
                {/*          Corrected Age Week*/}
                {/*        </FormLabel>*/}
                {/*        <Col lg={6}>*/}
                {/*          <InputGroup className="mb-3">*/}
                {/*            <InputGroup.Prepend>*/}
                {/*              <InputGroup.Text>weeks</InputGroup.Text>*/}
                {/*            </InputGroup.Prepend>*/}
                {/*            <FormControl {...field} />*/}
                {/*          </InputGroup>*/}
                
                {/*          <ErrorMessage name="correctedAgeWeekComponent">*/}
                {/*            {msg => (*/}
                {/*              <ErrorMessageStyled>{msg}</ErrorMessageStyled>*/}
                {/*            )}*/}
                {/*          </ErrorMessage>*/}
                {/*        </Col>*/}
                {/*      </FormGroup>*/}
                {/*    </>*/}
                {/*  )}*/}
                {/*</Field>*/}
                
                {/*/!* correctedAgeDayComponent *!/*/}
                {/*<Field name="correctedAgeDayComponent">*/}
                {/*  {({*/}
                {/*    field // { name, value, onChange, onBlur }*/}
                {/*  }) => (*/}
                {/*    <>*/}
                {/*      <FormGroup as={Row} className="mt-4">*/}
                {/*        <FormLabel column lg={6}>*/}
                {/*          Corrected Age Days*/}
                {/*        </FormLabel>*/}
                {/*        <Col lg={6}>*/}
                {/*          <InputGroup className="mb-3">*/}
                {/*            <InputGroup.Prepend>*/}
                {/*              <InputGroup.Text>days</InputGroup.Text>*/}
                {/*            </InputGroup.Prepend>*/}
                {/*            <FormControl {...field} />*/}
                {/*          </InputGroup>*/}
                
                {/*          <ErrorMessage name="correctedAgeDayComponent">*/}
                {/*            {msg => (*/}
                {/*              <ErrorMessageStyled>{msg}</ErrorMessageStyled>*/}
                {/*            )}*/}
                {/*          </ErrorMessage>*/}
                {/*        </Col>*/}
                {/*      </FormGroup>*/}
                {/*    </>*/}
                {/*  )}*/}
                {/*</Field>*/}

                {/* Number of children */}
                <FormGroup as={Row}>
                  <FormLabel column lg={6}>
                    Number of children
                  </FormLabel>
                  <Col lg={6}>
                    <Field
                      name="numberOfChildren"
                      component={FormikCustomInput}
                      onChange={value => handleChangeNumChildren(value)}
                    />
                    <ErrorMessage name="numberOfChildren">
                      {msg => <ErrorMessageStyled>{msg}</ErrorMessageStyled>}
                    </ErrorMessage>
                  </Col>
                </FormGroup>

                {/* Children's Sex */}
                {childrenArray.length > 0 &&
                  childrenArray.map(child => (
                    <Field name={child.name}>
                      {({ field }) => {
                        return (
                          <FormGroup as={Row}>
                            <FormLabel column sm={6}>
                              {child.label}
                            </FormLabel>
                            <Col sm={6} className="pt-2">
                              {sexOptions.map(option => {
                                return (
                                  <FormCheck
                                    inline
                                    {...field}
                                    type="radio"
                                    label={option.key}
                                    value={option.value}
                                  />
                                )
                              })}
                            </Col>
                          </FormGroup>
                        )
                      }}
                    </Field>
                  ))}

                {/* Primary Clinician */}
                {userData && userData.role === ROLE.ADMIN && (
                  <Field name="primaryDoctorUsername">
                    {({ field, form }) => (
                      <>
                        <FormGroup as={Row} className="mt-4">
                          <FormLabel column lg={6}>
                            Primary Clinician
                          </FormLabel>
                          <Col lg={6}>
                            <Select
                              name={field.name}
                              options={clinicianOptions}
                              defaultValue={clinicianOptions.find((option) => option.value === field.value) || ""}
                              isSearchable
                              isClearable
                              onChange={option =>
                                form.setFieldValue(field.name, option.value)
                              }
                            />
                            <ErrorMessage name="primaryDoctorUsername">
                              {msg => (
                                <ErrorMessageStyled>{msg}</ErrorMessageStyled>
                              )}
                            </ErrorMessage>
                          </Col>
                        </FormGroup>
                      </>
                    )}
                  </Field>
                )}

                <Field name="secondaryDoctorUsernames">
                  {({ field, form }) => (
                    <>
                      <FormGroup as={Row} className="mt-4">
                        <FormLabel column lg={6}>
                          Secondary Clinicians
                        </FormLabel>
                        <Col lg={6}>
                          <Select
                            name={field.name}
                            options={clinicianOptions}
                            isSearchable
                            isClearable
                            isMulti
                            onChange={options =>
                              form.setFieldValue(
                                field.name,
                                map(options, option => option.value)
                              )
                            }
                          />
                          <ErrorMessage name="secondaryDoctorUsernames">
                            {msg => (
                              <ErrorMessageStyled>{msg}</ErrorMessageStyled>
                            )}
                          </ErrorMessage>
                        </Col>
                      </FormGroup>
                    </>
                  )}
                </Field>

                <Field name="notificationEmailAddress">
                  {({ field }) => (
                    <>
                      <FormGroup as={Row} className="mt-4">
                        <FormLabel column lg={6}>
                          Notification Email Address:
                        </FormLabel>
                        <Col lg={6}>
                          <FormControl {...field} />
                          <ErrorMessage name="notificationEmailAddress">
                            {msg => (
                              <ErrorMessageStyled>{msg}</ErrorMessageStyled>
                            )}
                          </ErrorMessage>
                        </Col>
                      </FormGroup>
                    </>
                  )}
                </Field>
              </Form>
            </ModalBodyStyled>
            <ModalFooterStyled>
              {/* Submit Button */}
              <ButtonStyled
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Please wait...' : 'CREATE'}
              </ButtonStyled>
            </ModalFooterStyled>
          </Modal>
        )}
      </Formik>
      <Modal show={openParticipantInfo} onHide={handleClose} size="lg">
        <ModalHeaderStyled closeButton>Participant Created</ModalHeaderStyled>
        <ModalBodyStyled>
          <strong>Participant ID: </strong>
          <span>{participantInfo.createdParticipantUsername}</span>
          <br />
          <strong>Participant Password: </strong>
          <span>{participantInfo.createdParticipantPassword}</span>
        </ModalBodyStyled>
        <Modal.Footer>
          <ButtonStyled
            variant="secondary"
            onClick={handleCloseParticipantInfo}
          >
            OK
          </ButtonStyled>
        </Modal.Footer>
      </Modal>
    </>
  )
}

ParticipantModal.propTypes = {}
export default ParticipantModal
