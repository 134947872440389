import React from 'react'
import { Navigate } from 'react-router-dom'
import MainLayout from 'layouts/MainLayout'
import AccountView from 'views/account/AccountView'
import PatientDashboardView from 'views/dashboard/participant/DashboardView'
import InterventionsView from 'views/dashboard/participant/InterventionsView'
import ParticipantInfoView from 'views/dashboard/admin/ParticipantInfoView'
import AdminDashboardView from 'views/dashboard/admin/DashboardView'
import LoginView from 'views/auth/LoginView'
import NotFoundView from 'views/errors/NotFoundView'
import RegisterView from 'views/auth/RegisterView'
import ProtectedRoute from './ProtectedRoute'

const routes = [
  {
    path: 'app',
    element: <ProtectedRoute />,
    children: [{ path: '*', element: <Navigate to="/404" /> }]
  },
  {
    path: 'app/participant',
    element: <ProtectedRoute />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'dashboard', element: <PatientDashboardView /> },
      { path: 'interventions', element: <InterventionsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'app/admin',
    element: <ProtectedRoute />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'dashboard', element: <AdminDashboardView /> },
      { path: 'participant_info', element: <ParticipantInfoView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'login',
    element: <LoginView />
  },
  {
    path: '/',
    element: <Navigate to="/login" />
  },
  {
    path: '/home',
    element: <MainLayout />,
    children: [
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/home/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
]

export default routes
