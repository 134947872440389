import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import QuestionnaireModal from 'components/forms/QuestionnaireModal'
import ParticipantModal from 'components/forms/ParticipantModal'
import reduxStore from './redux-store'
import * as serviceWorker from './serviceWorker'
import './index.scss'
import App from './App'
import 'react-toastify/dist/ReactToastify.css'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'

ReactDOM.render(
  <BrowserRouter>
    <Provider store={reduxStore}>
      <App />
      <QuestionnaireModal />
      <ParticipantModal />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)

serviceWorker.unregister()
