import styled from 'styled-components'
import { Button } from 'react-bootstrap'

const ButtonStyled = styled(Button)`
  background-color: #e0927f;
  border-color: #e0927f;
  border-radius: 27px;
  padding: 5px 25px;
  //float: right;

  :hover {
    background-color: #b28364;
    border-color: #b28364;
  }
`
export default ButtonStyled
