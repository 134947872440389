import styled from 'styled-components'
import { Button } from 'react-bootstrap'

const PaginationButton = styled(Button)`
  font-size: 0.7rem;
  line-height: 1.2rem;
  color: black;
  background-color: #f6e2ca;
  border-color: #f6e2ca;
  padding: 0.2rem 0.5rem;

  :disabled {
    background-color: #e1e1e1;
    border-color: #e1e1e1;
    padding: 0.2rem 0.5rem;
    color: black;
    :hover {
      pointer-events: none;
    }
  }
  :hover {
    background-color: #e0927f;
    border-color: #e0927f;
    padding: 0.2rem 0.5rem;
    color: black;
  }
`

export default PaginationButton
