import styled from 'styled-components'
import { Button, Card } from 'react-bootstrap'

export const DashboardCard = styled(Card)`
  margin-bottom: 20px;
  border: none;
`
export const DashboardCardHeader = styled(Card.Header)`
  box-shadow: none;
  color: ${props => !props.iscompleted && 'black'} !important;
  background-color: ${props =>
    props.iscompleted === 'true' ? '#d2d2d2' : '#fff6e4'};
  border: ${props =>
    props.iscompleted === 'true' ? '#e5e5e5' : '1px solid #f4e5cf'};
  border: none !important ;
  border-radius: 15px !important;
  padding: 1px 10px 4px 10px !important;
  font-family: 'Nunito', sans-serif;
  font-size: 1.3rem;

  @media (max-width: 480px) {
    padding: 1px 0px 4px 0px !important;
    font-size: 1rem !important;
  }
`
export const DashboardCardBody = styled(Card.Body)`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: ${props =>
    !props.isActive ? 'none !important' : '1px solid #f4e5cf'};
  border-top: none;
  padding: 10px 0 30px 40px;
`
export const DashboardCardToggle = styled(Card.Header)`
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
  padding: 7px 10px;
`
export const DashboardButton = styled(Button)`
  background-color: #e0927f;
  border-color: #e0927f;
  border-radius: 27px;
  padding: 4px 16px;

  @media (max-width: 480px) {
    margin-right: 0px;
  }

  :hover,
  :focus {
    background-color: #b28364;
    border-color: #b28364;
  }
`
