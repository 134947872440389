/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import { loadingHOC } from 'services/loadingHOC'
import PropTypes from 'prop-types'
import { Accordion, Card, Col, Container, Row } from 'react-bootstrap'
import PageSection from 'components/PageSection'
import PageHeader from 'components/PageHeader'
import PageTitle from 'components/PageTitle'
import { useDispatch, useSelector } from 'react-redux'
import { find, map, isEmpty, sortBy, forEach } from 'lodash';
import png3 from 'assets/images/designs/Screen Shot 2021-03-25 at 7.55.31 pm.png'
import { ArrowIosDownwardOutline } from '@styled-icons/evaicons-outline/ArrowIosDownwardOutline'
import {
  DashboardButton,
  DashboardCard,
  DashboardCardBody,
  DashboardCardHeader,
  DashboardCardToggle
} from 'components/Dashboard'
import ReactPlayer from 'react-player/lazy'
import adminActions from 'store/actions/adminActions'
import { useLocation } from 'react-router'
import {
  PhaseCardSmaller,
  PhaseCardSmallerBody,
  PhaseCol
} from 'components/Phase'
import { NUMBER_TO_WORD } from 'constants/consts'
import { BadgeStyled, H2BadgeStyled } from 'components/BadgeStyled'
import {
  InterventionCard,
  InterventionCardContainer,
  InterventionCardContainerBody,
  InterventionCardContainerHeader,
  InterventionCardHeader
} from 'components/Intervention'
import { ArrowIosUpwardOutline } from '@styled-icons/evaicons-outline';

const Interventions = () => {
  const dispatch = useDispatch()
  const { state } = useLocation()
  const userData = useSelector(store => store.user.data)
  const [interventions, setInterventions] = useState({})
  const [educationals, setEducationals] = useState({})
  const [phase, setPhase] = useState(undefined)

  useEffect(() => {
    setPhase(state.phase)
    dispatch(
      adminActions.fetchInterventionsStatus(
        userData.username,
        `PHASE_${state.phase}`,
        null
      )
    ).then(statuses => {
      let list = []
      let educationalList = []
      const { interventionMap, interventionStatuses } = statuses
      map(interventionMap, value => {
        const item = find(interventionStatuses, {
          interventionId: value.interventionId
        })
        if (item && item.interventionAvailableToParticipant && value.interventionType === 'INTERVENTION')
          list = [...list, value]

        if (item && item.interventionAvailableToParticipant && value.interventionType === 'EDUCATIONAL')
          educationalList = [...educationalList, value]
      })
      let displayOrderInterventions = sortBy(list, ["displayOrder"])

      forEach(displayOrderInterventions, function(value) {
        value.documents = sortBy(value.documents, ["displayOrder"])
        value.videos = sortBy(value.videos, ["displayOrder"])
      });

      setInterventions(displayOrderInterventions)
      setEducationals(educationalList)
    })
  }, [])

  return (
    <Container className="pl-4">
      <PageHeader className="justify-content-between">
        <Col md={9}>
          <PageTitle>INTERVENTION DASHBOARD</PageTitle>
          <br />
          <p>
            Welcome to Phase {NUMBER_TO_WORD[phase]} of the TEDI-Prem intervention program. Below you will find the information sheets and videos that your TEDI-Prem therapist has made available to you.
            By clicking on the topic you're interested in you will be able to view and download the information sheet and watch the supporting videos.
            As you progress through Phase {NUMBER_TO_WORD[phase]} of the program, your TEDI-Prem therapist will add new information sheets and videos for you to view.
            {phase === 3 ? `You are also able to go back and view any of the content from Phase One and Two if you wish.` : ``}
          </p>
        </Col>
        <PhaseCol md={{ span: 3 }} className="pull-right">
          {state.disabled && (
            <H2BadgeStyled className="float-right">
              <BadgeStyled variant="dark">Archive</BadgeStyled>
            </H2BadgeStyled>
          )}
          <PhaseCardSmaller className="float-right">
            <img variant="top" src={png3} alt="" />
            <PhaseCardSmallerBody className="text-center">
              Phase {NUMBER_TO_WORD[phase]}
            </PhaseCardSmallerBody>
          </PhaseCardSmaller>
        </PhaseCol>
      </PageHeader>
      <PageSection>
        <Col>
          {map(interventions, tab => (
            <Accordion
              key={tab.interventionId}
            >
              <DashboardCard>
                <DashboardCardHeader>
                  <Accordion.Toggle
                    as={DashboardCardToggle}
                    eventKey={tab.interventionId}
                  >
                    {tab.collapsed ? (
                      <ArrowIosDownwardOutline
                        size={20}
                        className="mr-2"
                        color="grey"
                      />
                    ) : (
                      <ArrowIosUpwardOutline
                        size={20}
                        className="mr-2"
                        color="grey"
                      />
                    )}
                    {tab.interventionName}
                  </Accordion.Toggle>
                </DashboardCardHeader>
                <Accordion.Collapse eventKey={tab.interventionId}>
                  <DashboardCardBody>
                    <p>{tab.interventionText}</p>

                    {/* PDFs */}
                    {map(tab.documents, doc => (
                      <InterventionCardContainer key={doc.docId}>
                        <InterventionCardContainerHeader as="h5">
                          PDF
                        </InterventionCardContainerHeader>
                        <InterventionCardContainerBody>
                          <Col md={6}>
                            <InterventionCard>
                              <InterventionCardHeader>
                                {doc.title}
                              </InterventionCardHeader>
                              <Card.Body>
                                <Card.Text>{doc.description}</Card.Text>
                                <DashboardButton
                                  onClick={e => e.stopPropagation()}
                                  className="float-right"
                                  target="_blank"
                                  href={doc.url}
                                >
                                  OPEN
                                </DashboardButton>
                              </Card.Body>
                            </InterventionCard>
                          </Col>
                        </InterventionCardContainerBody>
                      </InterventionCardContainer>
                    ))}

                    {/* Videos */}

                  {!isEmpty(tab.videos) && (
                    <InterventionCardContainer className="mt-3">
                      <InterventionCardContainerHeader as="h5">
                        VIDEO
                      </InterventionCardContainerHeader>
                      <InterventionCardContainerBody>
                        <Row>
                          {map(tab.videos, video => (
                            <Col md={6} className="d-flex" key={video.videoId}>
                              <InterventionCard>
                                <InterventionCardHeader>
                                  {video.title}
                                </InterventionCardHeader>
                                <Card.Body>
                                  <Card.Text>{video.description}</Card.Text>
                                  <ReactPlayer
                                    controls
                                    width="100%"
                                    height="50"
                                    url={video.url}
                                    preload="none"
                                  />
                                </Card.Body>
                              </InterventionCard>
                            </Col>
                          ))}
                        </Row>
                      </InterventionCardContainerBody>
                    </InterventionCardContainer>
                    )}
                  </DashboardCardBody>
                </Accordion.Collapse>
              </DashboardCard>
            </Accordion>
          ))}
        </Col>
      </PageSection>
      <PageSection>
        <PageTitle>
        Educational Videos
        </PageTitle>
        <Row>
          {map(educationals, education => (
          <Row>
            {map(education.videos, video => (
              <Card.Body>
                <h5>
                  {video.title}
                </h5>
                <p>{video.description}</p>
                <ReactPlayer
                controls
                width="50%"
                height="50"
                url={video.url}
                preload="none"
                />
              </Card.Body>
              ))}
          </Row>
        ))}
        </Row>
      </PageSection>
    </Container>
  )
}

Interventions.propTypes = {
  viewStateManager: PropTypes.shape({
    setLoading: PropTypes.func,
    setReadyDelayed: PropTypes.func
  })
}
export default loadingHOC(Interventions)
