import { userActionTypes } from 'store/actions/actionTypes'
import store from 'store'

const initialState = {
  isAuthenticated: store.get('isAuthenticated'),
  isSessionExpired: true,
  errorMessage: '',
  data: store.get('userData')
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.LOGIN_REQUEST: {
      return {
        ...state,
        isAuthenticated: false
      }
    }

    case userActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        errorMessage: '',
        data: action.data
      }
    }

    case userActionTypes.LOGIN_FAILURE: {
      return {
        ...state,
        isAuthenticated: false,
        errorMessage: action.loginError,
        data: {}
      }
    }

    case userActionTypes.SESSION_EXTENDED: {
      return {
        ...state,
        isSessionExpired: false,
        data: store.get('userData')
      }
    }

    case userActionTypes.SESSION_EXPIRED: {
      return {
        ...state,
        isSessionExpired: true
      }
    }

    case userActionTypes.USER_SUCCESS: {
      return {
        ...state,
        data: action.data,
        errorMessage: ''
      }
    }
    case userActionTypes.USER_FAILURE: {
      return {
        ...state,
        data: {},
        errorMessage: action.userError
      }
    }

    case userActionTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        isAuthenticated: false,
        data: {},
        errorMessage: ''
      }
    }

    default: {
      return state
    }
  }
}
export default user
