import { PARTICIPANTActionTypes } from 'store/actions/actionTypes'
import store from 'store'

const initialState = {
  participantModalOpen: false,
  assignmentModalOpen: false,
  participants: store.get('participants'),
  ifFetchList: false,
  interventionMap: {},
  questionnairesStatuses: [],
  interventionStatuses: [],
  notificationStatuses: []
}

const admin = (state = initialState, action) => {
  switch (action.type) {
    case PARTICIPANTActionTypes.UPDATE_FETCH_LIST:
      return {
        ...state,
        ifFetchList: action.bool
      }

    case PARTICIPANTActionTypes.SHOW_PARTICIPANT_MODAL:
      return {
        ...state,
        participantModalOpen: true
      }

    case PARTICIPANTActionTypes.HIDE_PARTICIPANT_MODAL:
      return {
        ...state,
        participantModalOpen: false
      }

    case PARTICIPANTActionTypes.LIST_SUCCESS:
      return {
        ...state,
        participants: action.data,
        ifFetchList: false
      }

    case PARTICIPANTActionTypes.LIST_FAILURE:
      return {
        ...state,
        participantModalOpen: true,
        participants: [],
        ifFetchList: false
      }

    case PARTICIPANTActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        ifFetchList: true
      }

    case PARTICIPANTActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        ifFetchList: true
      }

    case PARTICIPANTActionTypes.FETCH_INTERVENTION_STATUSES:
      return {
        ...state,
        interventionMap: action.statuses.interventionMap,
        interventionStatuses: action.statuses.interventionStatuses
      }

    case PARTICIPANTActionTypes.FETCH_NOTIFICATION_STATUSES:
      return {
        ...state,
        notificationStatuses: action.statuses
      }

    case PARTICIPANTActionTypes.FETCH_QUESTIONNAIRES_STATUSES:
      return {
        ...state,
        questionnairesStatuses: action.statuses
      }

    case PARTICIPANTActionTypes.INTERVENTION_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        ifFetchList: true
      }

    case PARTICIPANTActionTypes.UPDATE_PHASE_SUCCESS:
      return {
        ...state,
        ifFetchList: true
      }

    default:
      return state
  }
}
export default admin
