import { QUESTIONNAIREActionTypes } from './actionTypes'
import { getQuestionnaires } from 'services/api';
import * as toasts from 'services/toasts';
import store from 'store';

const show = (
  questionnaireType,
  result,
  schemas,
  steps,
  tedipremId,
  questionnaireCompletionCallback
) => dispatch => {
  dispatch({
    type: QUESTIONNAIREActionTypes.SHOW,
    questionnaireType,
    result,
    schemas,
    steps,
    tedipremId,
    questionnaireCompletionCallback
  })
}

const hide = () => dispatch => {
  dispatch({
    type: QUESTIONNAIREActionTypes.HIDE
  })
}

const fetchQuestionnaires = () => {
  const fetchSuccess = list => {
    return {
      type: QUESTIONNAIREActionTypes.QUESTIONNAIRES_LIST_SUCCESS,
      list
    }
  }
  return async dispatch => {
    return getQuestionnaires()
      .then(list => {
        dispatch(fetchSuccess(list))
        store.set('questionnaires', list)
        return list
      })
      .catch(error => {
        toasts.error(error.response.data.message)
      })
  }
}


export default {
  show,
  hide,
  fetchQuestionnaires
}
