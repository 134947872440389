import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import ButtonStyled from 'components/ButtonStyled'

const QuestionnaireBotBar = props => {
  const { activeStep, stepsLength, onBack } = props

  const handleBack = () => {
    onBack()
  }

  return (
    <Row>
      <Col>
        {activeStep !== 0 && (
          <ButtonStyled onClick={handleBack}>Back</ButtonStyled>
        )}
      </Col>
      <Col>
        {activeStep === stepsLength - 1 ? (
          <ButtonStyled className="float-right" type="submit">
            Submit
          </ButtonStyled>
        ) : (
          <ButtonStyled className="float-right" type="submit">
            Next
          </ButtonStyled>
        )}
      </Col>
    </Row>
  )
}

QuestionnaireBotBar.propTypes = {
  activeStep: PropTypes.number,
  stepsLength: PropTypes.number,
  onBack: PropTypes.func
}

export default QuestionnaireBotBar
