/* eslint-disable camelcase */
import styled from 'styled-components'

const LISidebar = styled.li`
  text-align: center;
  border-radius: 10px;
  margin: 10px 1px;
  background-color: ${props => (props.disabled ? '#e5e5e5' : '#a6bfbe')};
  pointer-events: ${props => (props.disabled ? 'none' : 'hand')};
  span {
    color: white;
    font-size: 1rem;
  }
  padding: 0px 27px;
`

export default LISidebar
