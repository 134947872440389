import React from 'react'
import { usePagination, useTable } from 'react-table'
import { Col, Row } from 'react-bootstrap'
import PaginationButton from 'components/PaginationButton'

const Table = props => {
  const { columns, data } = props

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    usePagination
  )

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      <Row className="mt-5">
        <Col xs={12} md={4} />
        <Col xs={12} md={4}>
          <PaginationButton
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </PaginationButton>{' '}
          <PaginationButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'<'}
          </PaginationButton>{' '}
          <PaginationButton className="btn-link">
            {pageIndex + 1}
          </PaginationButton>
          <PaginationButton
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="ml-1"
          >
            {'>'}
          </PaginationButton>{' '}
          <PaginationButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </PaginationButton>{' '}
        </Col>
        <Col xs={12} md={4} />
      </Row>
    </>
  )
}
export default Table
