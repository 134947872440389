/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { Col, Row } from 'react-bootstrap'
import {
  PopoverButtonStyled,
  PopoverHeaderStyled,
  PopoverStyled
} from 'components/PopoverStyled'
import adminActions from 'store/actions/adminActions'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { PHASE_OPTIONS } from 'constants/consts'
import { find } from 'lodash'

const PhasePopover = React.forwardRef(({ ...props }, ref) => {
  const dispatch = useDispatch()
  const { cell, value } = props
  const [nextPhase, setNextPhase] = useState('PHASE_1')

  useEffect(() => {
    if (value === 'PHASE_1') setNextPhase('PHASE_2')
    else if (value === 'PHASE_2') setNextPhase('PHASE_3')
  }, [value])

  const handleUpdate = () => {
    dispatch(
      adminActions.participantUpdate(cell.row.values.username, {
        phase: nextPhase
      })
    ).then(() =>
      setTimeout(() => {
        document.body.click()
      }, 100)
    )
  }

  return (
    <PopoverStyled ref={ref} content {...props}>
      <PopoverHeaderStyled>Phase Update</PopoverHeaderStyled>

      <Formik
        initialValues={{
          phase: nextPhase
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false)
          handleUpdate(values)
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form>
            {/* Group */}
            <Row>
              <Col sm={12} className="text-center mt-3 pb-0">
                <Field name="phase">
                  {({ field }) => {
                    return (
                      <p>
                        Go to {find(PHASE_OPTIONS, { value: nextPhase }).label}?
                      </p>
                    )
                  }}
                </Field>
              </Col>
              <Col sm={12} className="text-center mb-2">
                <PopoverButtonStyled
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Please wait...' : 'Confirm'}
                </PopoverButtonStyled>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </PopoverStyled>
  )
})

PhasePopover.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      values: PropTypes.shape({
        username: PropTypes.string
      })
    })
  }),
  value: PropTypes.string
}
export default PhasePopover
