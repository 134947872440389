import { QUESTIONNAIREActionTypes } from 'store/actions/actionTypes'
import store from 'store';

const initialState = {
  questionnaireType: null,
  questionnaires: store.get('questionnaires'),
  result: {},
  schemas: [],
  steps: [],
  tedipremId: null,
  open: false,
  completionCallback: null
}

const questionnaire = (state = initialState, action) => {
  switch (action.type) {
    case QUESTIONNAIREActionTypes.QUESTIONNAIRES_LIST_SUCCESS:
      return {
        ...state,
        questionnaires: action.list
      }

    case QUESTIONNAIREActionTypes.SHOW:
      return {
        questionnaireType: action.questionnaireType,
        result: action.result,
        schemas: action.schemas,
        steps: action.steps,
        tedipremId: action.tedipremId,
        type: action.type,
        open: true,
        completionCallback: action.questionnaireCompletionCallback
      }
    case QUESTIONNAIREActionTypes.HIDE:
      return initialState
    default:
      return state
  }
}
export default questionnaire
