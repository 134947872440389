import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Bars } from '@styled-icons/fa-solid/Bars'
import logoSmall from 'assets/images/designs/TEDI-Prem logo_final small.png'
import userActions from 'store/actions/userActions'
import { ROUTES } from 'constants/consts'
import { useNavigate, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import LogOutIcon from 'components/icons/LogOutIcon'
import { IconWrapper28 } from 'components/icons/styles'
import { Button } from 'react-bootstrap'
import { Dashboard } from '@styled-icons/boxicons-solid'
import PropTypes from 'prop-types'

const Header = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const userData = useSelector(state => state.user.data)

  useEffect(() => {}, [])

  const openCloseSidebar = () => {
    props.openCloseSidebar()
  }

  const handleLogout = () => {
    dispatch(userActions.logout()).then(() => {
      navigate(ROUTES.LOGIN, { replace: true })
    })
  }
  const handleBack = () => {
    navigate(-1)
  }

  const sidebarBtnOpen = !props.sidebar ? ' sidebar-btn-open' : ''

  return (
    <>
      <div className="page-main-header">
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none">
            <div className={`logo-wrapper ${sidebarBtnOpen}`}>
              <Link to="/app/dashboard">
                <img className="img-fluid" src={logoSmall} alt=""/>
              </Link>
            </div>
          </div>

          <div className={`mobile-sidebar d-lg-none ${sidebarBtnOpen}`}>
            <div className="media-body text-right switch-sm pull-right">
              <div className="switch">
                <button
                  type="button"
                  className="btn btn-link px-0"
                  onClick={openCloseSidebar}
                >
                  <Bars color="black" />
                </button>
              </div>
            </div>
          </div>

          <div>
            {location.pathname === '/app/participant/interventions' ||
            location.pathname === '/app/admin/participant_info' ? (
              <div className="float-left col p-0">
                <Button variant="link" onClick={handleBack}>
                  <Dashboard height={16} width={16} className="mr-1 mt-n1" />
                  Dashboard
                </Button>
              </div>
            ) : null}
          </div>

          <div className="nav-right col p-0">
            <span className="text-muted">{userData.username}</span>
            <button
              type="button"
              className="btn btn-link px-0"
              onClick={handleLogout}
            >
              <IconWrapper28>
                <LogOutIcon />
              </IconWrapper28>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

Header.propTypes = {
  openCloseSidebar: PropTypes.func,
  sidebar: PropTypes.bool
}
export default Header
