import React, { useEffect } from 'react'
import AutoLagoutTimer from 'routes/AutoLagoutTimer'
import userActions from 'store/actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import { ROUTES } from 'constants/consts'
import DashboardLayout from '../layouts/DashboardLayout'

const ProtectedRoute = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(state => state.user.isAuthenticated)

  useEffect(() => {
    dispatch(userActions.extendSession())
  }, [isAuthenticated])

  return isAuthenticated ? (
    <AutoLagoutTimer ComposedClass={DashboardLayout} />
  ) : (
    <Navigate to={ROUTES.LOGIN} replace />
  )
}

export default ProtectedRoute
