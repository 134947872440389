import React from 'react'
import phase1Img from 'assets/images/designs/new/phase one - 300px.png'
import phase2Img from 'assets/images/designs/new/phase two - 300px.png'
import phase3Img from 'assets/images/designs/new/phase three - 300px.png'

const EMAIL = (
  <a href="mailto:tedi-prem@unimelb.edu.au">tedi-prem@unimelb.edu.au</a>
)

export const PATIENT_DEPENDENTS = {
  CHILD: 'child',
  CHILDREN: 'children',
  BABY: 'baby',
  BABIES: 'babies'
}

export const TIMEPOINT_KEYS = {
  BASELINE: 'BASELINE',
  MONTH_3: 'THREE_MONTHS_CORRECTED_AGE',
  MONTH_6: 'SIX_MONTHS_CORRECTED_AGE',
  MONTH_12: 'TWELVE_MONTHS_CORRECTED_AGE',
  MONTH_18: 'EIGHTEEN_MONTHS_CORRECTED_AGE',
  MONTH_24: 'TWENTY_FOUR_MONTHS_CORRECTED_AGE'
}

export const QUESTIONNAIRES_KEYS = {
  SOCIO_DEMOGRAPHIC: 'SOCIO_DEMOGRAPHIC',
  KPSE: 'KPSE',
  DASS21: 'DASS21',
  TANDI: 'TANDI',
  SF_6D: 'SF_6D',
  THERAPY: 'THERAPY',
  PLAY: 'PLAY'
}

export const PHASES_ITEMS = (questionnaire, timepoint, childNum) => {
  let babyStr = PATIENT_DEPENDENTS.BABY
  if (
    timepoint !== TIMEPOINT_KEYS.BASELINE &&
    timepoint !== TIMEPOINT_KEYS.MONTH_3 &&
    timepoint !== TIMEPOINT_KEYS.MONTH_6
  )
    babyStr = PATIENT_DEPENDENTS.CHILD

  if (childNum > 1) {
    babyStr = PATIENT_DEPENDENTS.BABIES
    if (
      timepoint !== TIMEPOINT_KEYS.BASELINE &&
      timepoint !== TIMEPOINT_KEYS.MONTH_3 &&
      timepoint !== TIMEPOINT_KEYS.MONTH_6
    ) {
      babyStr = PATIENT_DEPENDENTS.CHILDREN
    }
  }

  switch (questionnaire) {
    case QUESTIONNAIRES_KEYS.SOCIO_DEMOGRAPHIC:
      return {
        key: QUESTIONNAIRES_KEYS.SOCIO_DEMOGRAPHIC,
        name: 'Socio_Demographic',
        title: 'You and Your Family',
        description:
          'This questionnaire tells us a little bit more about you and your family including your living and working situation and education.',
        timePoints: [
          TIMEPOINT_KEYS.BASELINE,
          TIMEPOINT_KEYS.MONTH_12,
          TIMEPOINT_KEYS.MONTH_24
        ],
        perChild: false,
        isCompleted: false
      }
    case QUESTIONNAIRES_KEYS.KPSE:
      return {
        key: QUESTIONNAIRES_KEYS.KPSE,
        name: 'KPSE',
        title: 'Parenting',
        description: `This questionnaire asks about how you’re feeling in your parenting role and the different activities involved in caring for your ${babyStr}.`,
        timePoints: [
          TIMEPOINT_KEYS.BASELINE,
          TIMEPOINT_KEYS.MONTH_3,
          TIMEPOINT_KEYS.MONTH_12,
          TIMEPOINT_KEYS.MONTH_24
        ],
        perChild: false,
        isCompleted: false
      }
    case QUESTIONNAIRES_KEYS.DASS21:
      return {
        key: QUESTIONNAIRES_KEYS.DASS21,
        name: 'DASS21',
        title: 'Your Wellbeing',
        description: `This questionnaire asks you about your mood and how you’ve been feeling over the past week.`,
        timePoints: [
          TIMEPOINT_KEYS.BASELINE,
          TIMEPOINT_KEYS.MONTH_3,
          TIMEPOINT_KEYS.MONTH_6,
          TIMEPOINT_KEYS.MONTH_12,
          TIMEPOINT_KEYS.MONTH_18,
          TIMEPOINT_KEYS.MONTH_24
        ],
        perChild: false,
        isCompleted: false
      }
    case QUESTIONNAIRES_KEYS.TANDI:
      return {
        key: QUESTIONNAIRES_KEYS.TANDI,
        name: 'TANDI',
        title: "Your Child's Health",
        description: `The purpose of this questionnaire is to explore how you would rate the health of your ${babyStr}.`,
        timePoints: [TIMEPOINT_KEYS.MONTH_12, TIMEPOINT_KEYS.MONTH_24],
        perChild: true,
        isCompleted: false
      }
    case QUESTIONNAIRES_KEYS.SF_6D:
      return {
        key: QUESTIONNAIRES_KEYS.SF_6D,
        name: 'SF_6D',
        title: 'Your Health',
        description:
          'This questionnaire asks for your views about your own health and how you feel you’re able to do your usual activities.',
        timePoints: [
          TIMEPOINT_KEYS.BASELINE,
          TIMEPOINT_KEYS.MONTH_12,
          TIMEPOINT_KEYS.MONTH_24
        ],
        perChild: false,
        isCompleted: false
      }
    case QUESTIONNAIRES_KEYS.THERAPY:
      return {
        key: QUESTIONNAIRES_KEYS.THERAPY,
        name: 'Therapy',
        title: 'Therapy services',
        description: `This questionnaire checks in to see if your ${babyStr} received any therapy services in the last month.`,
        timePoints: [
          TIMEPOINT_KEYS.BASELINE,
          TIMEPOINT_KEYS.MONTH_3,
          TIMEPOINT_KEYS.MONTH_6,
          TIMEPOINT_KEYS.MONTH_12,
          TIMEPOINT_KEYS.MONTH_18,
          TIMEPOINT_KEYS.MONTH_24
        ],
        perChild: true,
        isCompleted: false
      }
    /*case QUESTIONNAIRES_KEYS.MPOC:
      return {
        key: QUESTIONNAIRES_KEYS.MPOC,
        name: 'MPOC',
        title: 'Your Experience of Care',
        description:
          'This questionnaire will ask you a series of questions about the extent to which health professionals working with you and your {babyStr} display certain behaviours and actions.',
        timePoints: [
          TIMEPOINT_KEYS.MONTH_12
        ],
        perChild: false,
        isCompleted: false
      }*/
    default:
      return {}
  }
}

export const TIMEPOINT = (timepoint, childNum) => {
  let babyStr = PATIENT_DEPENDENTS.BABY
  if (childNum > 1) {
    babyStr = PATIENT_DEPENDENTS.BABIES
  }
  switch (timepoint) {
    case TIMEPOINT_KEYS.BASELINE:
      return {
        name: 'Baseline',
        descriptionText: (
          <div>
            Thank you for being part of the “TEDI-Prem” study. To finalise your
            enrolment in the study, please complete the following
            questionnaires. If you need any help answering these questions or
            would like to speak to a TEDI-Prem therapist about any aspect of the
            study, please contact us at {EMAIL}. Thank you.
          </div>
        ),
        completionText: (
          <div>
            Thank you for your responses and for being a part of the TEDI-Prem
            study. A TEDI-Prem therapist will contact you shortly to explain the
            next steps in the study. If responding to these questions has raised
            any feelings of distress for you, there are many ways to access
            support. Your GP, BeyondBlue (www.beyondblue.org.au), PANDA (https://www.panda.org.au)
            and Lifeline (www.lifeline.org.au) are all good places to start if you wish to
            obtain further support. You can also contact the study team at
            {EMAIL} if you would like to discuss any related issue with us.
          </div>
        )
      }
    case TIMEPOINT_KEYS.MONTH_3:
      return {
        name: '3 months',
        descriptionText: (
          <div>
            Thank you for being part of the “TEDI-Prem” study. Now that your{' '}
            {babyStr} {childNum === 1 ? `is` : `are`} 3 months’ corrected age,
            we’d like to check in and ask you some questions. If you have any questions or would like to talk to a
            TEDI-Prem therapist, please contact us at {EMAIL}. Thank you.
          </div>
        ),
        completionText:
          (
            <div>
              Thank you for your responses and for being a part of the TEDI-Prem
              study. If responding to these questions has raised
              any feelings of distress for you, there are many ways to access
              support. Your GP, BeyondBlue (www.beyondblue.org.au), PANDA (https://www.panda.org.au)
              and Lifeline (www.lifeline.org.au) are all good places to start if you wish to
              obtain further support. You can also contact the study team at
              {EMAIL} if you would like to discuss any related issue with us.
            </div>
          )
      }
    case TIMEPOINT_KEYS.MONTH_6:
      return {
        name: '6 months',
        descriptionText: (
          <div>
            Thank you for being part of the “TEDI-Prem” study. Now that your{' '}
            {babyStr} {childNum === 1 ? `is` : `are`} 6 months’ corrected age,
            we’d like to check in and ask you some questions. If you have any questions or would like to talk to a
            TEDI-Prem therapist, please contact us at {EMAIL}. Thank you.
          </div>
        ),
        completionText:
          (
            <div>
              Thank you for your responses and for being a part of the TEDI-Prem
              study. If responding to these questions has raised
              any feelings of distress for you, there are many ways to access
              support. Your GP, BeyondBlue (www.beyondblue.org.au), PANDA (https://www.panda.org.au)
              and Lifeline (www.lifeline.org.au) are all good places to start if you wish to
              obtain further support. You can also contact the study team at
              {EMAIL} if you would like to discuss any related issue with us.
            </div>
          )
      }
    case TIMEPOINT_KEYS.MONTH_12:
      return {
        name: '12 months',
        descriptionText: (
          <div>
            Thank you for being part of the “TEDI-Prem” study. Now that your{' '}
            {babyStr} {childNum === 1 ? `is` : `are`} 12 months’ corrected age,
            we’d like to check in and ask you some questions about your family, your role as a parent,
            how you’ve been feeling, how you rate your own health and your {babyStr}'s,
            your experience of the care you've received from health professionals and the types of therapy
            services, if any, you and your {babyStr} have received in the past
            month. We look forward to seeing you and your {babyStr} at their up and coming TEDI-Prem follow-up {childNum === 1 ? `appointment` : `appointments`}.
            If you have any questions or would like to talk to a TEDI-Prem therapist, please contact us at {EMAIL}. Thank you.
          </div>
        ),
        completionText:
          (
            <div>
              Thank you for your responses and for being a part of the TEDI-Prem
              study. If responding to these questions has raised
              any feelings of distress for you, there are many ways to access
              support. Your GP, BeyondBlue (www.beyondblue.org.au), PANDA (https://www.panda.org.au)
              and Lifeline (www.lifeline.org.au) are all good places to start if you wish to
              obtain further support. You can also contact the study team at
              {EMAIL} if you would like to discuss any related issue with us.
            </div>
          )
      }
    case TIMEPOINT_KEYS.MONTH_18:
      return {
        name: '18 months',
        descriptionText: (
          <div>
            Thank you for being part of the “TEDI-Prem” study. Now that your{' '}
            {babyStr} {childNum === 1 ? `is` : `are`} 18 months’ corrected age,
            we’d like to check in and ask you some questions about the types of therapy
            services, if any, you and your {babyStr} have received in the past
            month. If you have any questions or would like to talk to a
            TEDI-Prem therapist, please contact us at {EMAIL}. Thank you.
          </div>
        ),
        completionText:
          (
            <div>
              Thank you for your responses and for being a part of the TEDI-Prem
              study. If responding to these questions has raised
              any feelings of distress for you, there are many ways to access
              support. Your GP, BeyondBlue (www.beyondblue.org.au), PANDA (https://www.panda.org.au)
              and Lifeline (www.lifeline.org.au) are all good places to start if you wish to
              obtain further support. You can also contact the study team at
              {EMAIL} if you would like to discuss any related issue with us.
            </div>
          )
      }
    case TIMEPOINT_KEYS.MONTH_24:
      return {
        name: '24 months',
        descriptionText: (
          <div>
            Thank you for being part of the “TEDI-Prem” study. Now that your{' '}
            {babyStr} {childNum === 1 ? `is` : `are`} 24 months’ corrected age,
            we’d like to check in and ask you some questions about your family, your role as a parent,
            how you’ve been feeling, how you rate your own health and your {babyStr}'s and the types of therapy
            services, if any, you and your {babyStr} have received in the past month.
            We look forward to seeing you and your {babyStr} at their up and coming TEDI-Prem follow-up {childNum === 1 ? `appointment` : `appointments`}.
            If you have any questions or would like to talk to a TEDI-Prem therapist, please contact us at {EMAIL}. Thank you.
          </div>
        ),
        completionText:
          (
            <div>
              Thank you for your responses and for being a part of the TEDI-Prem
              study. If responding to these questions has raised
              any feelings of distress for you, there are many ways to access
              support. Your GP, BeyondBlue (www.beyondblue.org.au), PANDA (https://www.panda.org.au)
              and Lifeline (www.lifeline.org.au) are all good places to start if you wish to
              obtain further support. You can also contact the study team at
              {EMAIL} if you would like to discuss any related issue with us.
            </div>
          )
      }
    case TIMEPOINT_KEYS.RANDOM:
      return {
        name: 'random',
        descriptionText: (
          <div>
            Thank you for being part of the “TEDI-Prem” study. This is a quick
            check in to see if you and your child spent time completing any of
            these activities in the past week. If you have any questions about
            the activities or would like to talk to a TEDI-Prem therapist,
            please contact us at tedi-prem@unimelb.edu.au To get started, click
            on the ‘Play time’ start button below. Thank you.
          </div>
        ),
        completionText:
          'Thank you for your responses and for being a part of the TEDI-Prem study. If you have any questions or would like to talk to a TEDI-Prem therapist, please contact us at tedi-prem@unimelb.edu.au \n' +
          'Thank you.\n'
      }
    default:
      return {}
  }
}

export const PHASE = num => {
  switch (num) {
    case 1:
      return {
        descriptionText: (
          <div>
            Welcome to Phase One of the TEDI-Prem intervention program. Below you will find the information sheets and videos that your TEDI-Prem therapist has made available to you.
            By clicking on the topic you're interested in you will be able to view and download the information sheet and watch the supporting videos.
            As you progress through Phase One of the program, your TEDI-Prem therapist will add new information sheets and videos for you to view.
          </div>
        )
      }
    case 2:
      return {
        descriptionText: (
          <div>
            Welcome to Phase Two of the TEDI-Prem intervention program. Below you will find the information sheets and videos that your TEDI-Prem therapist has made available to you.
            By clicking on the topic you're interested in you will be able to view and download the information sheet and watch the supporting videos.
            As you progress through Phase Two of the program, your TEDI-Prem therapist will add new information sheets and videos for you to view.
          </div>
        )
      }
    case 3:
      return {
        descriptionText: (
          <div>
            Welcome to Phase Three of the TEDI-Prem intervention program. Below you will find the information sheets and videos that your TEDI-Prem therapist has made available to you.
            By clicking on the topic you're interested in you will be able to view and download the information sheet and watch the supporting videos.
            As you progress through Phase Three of the program, your TEDI-Prem therapist will add new information sheets and videos for you to view.
            You are also able to go back and view any of the content from Phase One and Two if you wish.
          </div>
        ),
        completionText:
          'Thank you for your responses and continuing to be a part of the TEDI-Prem study. If responding to these questions has raised any feelings of distress for you, there are many ways to access support. Your GP, BeyondBlue (www.beyondblue.org.au), and Lifeline (www.lifeline.org.au) are all good places to start if you wish to obtain further support. You can also contact the study team at tedi-prem@unimelb.edu.au if you would like to discuss any related issue with us.'
      }
    default:
      return {}
  }
}

export const QUESTIONNAIRE_PLAY = (timepoint, childNum) => {
  let babyStr = PATIENT_DEPENDENTS.BABY
  if (childNum > 1) babyStr = PATIENT_DEPENDENTS.BABIES
  if (
    timepoint === TIMEPOINT_KEYS.BASELINE ||
    timepoint === TIMEPOINT_KEYS.MONTH_3 ||
    timepoint === TIMEPOINT_KEYS.MONTH_6
  ) {
    babyStr = PATIENT_DEPENDENTS.CHILD
    return {
      key: QUESTIONNAIRES_KEYS.PLAY,
      title: 'Play',
      name: 'Play',
      description: (
        <div>
          This questionnaire will be sent to you every two months throughout the
          TEDI-Prem study.
          <br /> It will ask you about the time you and your ${babyStr} spend
          completing different activities.
          <br /> If the start button is not currently active then it means
          there’s no questionnaire to complete.
          <br /> We will send you an alert when it’s time.
        </div>
      ),
      timePoints: [
        TIMEPOINT_KEYS.BASELINE,
        TIMEPOINT_KEYS.MONTH_3,
        TIMEPOINT_KEYS.MONTH_6
      ],
      perChild: true,
      isCompleted: false
    }
  }
  babyStr = PATIENT_DEPENDENTS.CHILD
  if (childNum > 1) babyStr = PATIENT_DEPENDENTS.CHILDREN
  return {
    key: QUESTIONNAIRES_KEYS.PLAY,
    name: 'Play_Post6Mths',
    title: 'Play',
    description: (
      <div>
        This questionnaire will be sent to you every two months throughout the
        TEDI-Prem study. It will ask you about the time you and your {babyStr}{' '}
        spend completing different activities. If the start button is not
        currently active then it means there’s no questionnaire to complete. We
        will send you an alert when it’s time.
      </div>
    ),
    timePoints: [
      TIMEPOINT_KEYS.MONTH_12,
      TIMEPOINT_KEYS.MONTH_18,
      TIMEPOINT_KEYS.MONTH_24
    ],
    perChild: true,
    isCompleted: false
  }
}

export const QUESTIONNAIRES = (questionnaire, timepoint, childNum) => {
  let babyStr = PATIENT_DEPENDENTS.BABY
  if (
    timepoint !== TIMEPOINT_KEYS.BASELINE &&
    timepoint !== TIMEPOINT_KEYS.MONTH_3 &&
    timepoint !== TIMEPOINT_KEYS.MONTH_6
  )
    babyStr = PATIENT_DEPENDENTS.CHILD

  if (childNum > 1) {
    babyStr = PATIENT_DEPENDENTS.BABIES
    if (
      timepoint !== TIMEPOINT_KEYS.BASELINE &&
      timepoint !== TIMEPOINT_KEYS.MONTH_3 &&
      timepoint !== TIMEPOINT_KEYS.MONTH_6
    ) {
      babyStr = PATIENT_DEPENDENTS.CHILDREN
    }
  }

  switch (questionnaire) {
    case QUESTIONNAIRES_KEYS.SOCIO_DEMOGRAPHIC:
      return {
        key: QUESTIONNAIRES_KEYS.SOCIO_DEMOGRAPHIC,
        name: 'Socio_Demographic',
        title: 'You and Your Family',
        description:
          'This questionnaire tells us a little bit more about you and your family including your living and working situation and education.',
        timePoints: [
          TIMEPOINT_KEYS.BASELINE,
          TIMEPOINT_KEYS.MONTH_12,
          TIMEPOINT_KEYS.MONTH_24
        ],
        perChild: false,
        isCompleted: false
      }
    case QUESTIONNAIRES_KEYS.KPSE:
      return {
        key: QUESTIONNAIRES_KEYS.KPSE,
        name: 'KPSE',
        title: 'Parenting',
        description: `This questionnaire asks about how you’re feeling in your parenting role and the different activities involved in caring for your ${babyStr}.`,
        timePoints: [
          TIMEPOINT_KEYS.BASELINE,
          TIMEPOINT_KEYS.MONTH_12,
          TIMEPOINT_KEYS.MONTH_24
        ],
        perChild: false,
        isCompleted: false
      }
    case QUESTIONNAIRES_KEYS.DASS21:
      return {
        key: QUESTIONNAIRES_KEYS.DASS21,
        name: 'DASS21',
        title: 'Your Wellbeing',
        description: `This questionnaire asks you about your mood and how you’ve been feeling over the past week.`,
        timePoints: [
          TIMEPOINT_KEYS.BASELINE,
          TIMEPOINT_KEYS.MONTH_3,
          TIMEPOINT_KEYS.MONTH_6,
          TIMEPOINT_KEYS.MONTH_12,
          TIMEPOINT_KEYS.MONTH_24
        ],
        perChild: false,
        isCompleted: false
      }
    case QUESTIONNAIRES_KEYS.TANDI:
      return {
        key: QUESTIONNAIRES_KEYS.TANDI,
        name: 'TANDI',
        title: "Your Child's Health",
        description: `The purpose of this questionnaire is to explore how you would rate the health of your ${babyStr}.`,
        timePoints: [
          TIMEPOINT_KEYS.MONTH_12,
          TIMEPOINT_KEYS.MONTH_24
        ],
        perChild: true,
        isCompleted: false
      }
    case QUESTIONNAIRES_KEYS.SF_6D:
      return {
        key: QUESTIONNAIRES_KEYS.SF_6D,
        name: 'SF_6D',
        title: 'Your Health',
        description:
          'This questionnaire asks for your views about your own health and how you feel you’re able to do your usual activities.',
        timePoints: [
          TIMEPOINT_KEYS.BASELINE,
          TIMEPOINT_KEYS.MONTH_12,
          TIMEPOINT_KEYS.MONTH_24
        ],
        perChild: false,
        isCompleted: false
      }
    case QUESTIONNAIRES_KEYS.THERAPY:
      return {
        key: QUESTIONNAIRES_KEYS.THERAPY,
        name: 'Therapy',
        title: 'Therapy Services',
        description: `This questionnaire checks in to see if your ${babyStr} received any therapy services in the last month.`,
        timePoints: [
          TIMEPOINT_KEYS.BASELINE,
          TIMEPOINT_KEYS.MONTH_3,
          TIMEPOINT_KEYS.MONTH_6,
          TIMEPOINT_KEYS.MONTH_12,
          TIMEPOINT_KEYS.MONTH_18,
          TIMEPOINT_KEYS.MONTH_24
        ],
        perChild: true,
        isCompleted: false
      }
      // TODO Add entry for MPOC and check entry for Play
    default:
      return {}
  }
}

export const QUESTIONNAIRES_SECTIONS = {
  SECTION_ID: 'sectionId',
  ORDER_OF_SECTION: 'orderOfSection',
  ORDER_WITHIN_SECTION: 'orderWithinSection'
}

export const PROGRESS = {
  COMPLETED: {
    value: 'COMPLETE',
    label: 'Completed'
  },
  INCOMPLETE: {
    value: 'INCOMPLETE',
    label: 'Incomplete'
  }
}

export const ROUTES = {
  LOGIN: '/login',
  ADMIN_DASHBOARD: '/app/admin/dashboard',
  PARTICIPANT_DASHBOARD: '/app/participant/dashboard',
  PARTICIPANT_INFO: '/app/admin/participant_info',
  INTERVENTIONS: '/app/participant/interventions'
}

export const SESSION = {
  SessionLifeTime: 15, // min
  ActivityIntervalAuth: 5, // min
  EXPIRED: 'Session Expired'
}

export const ROLE = {
  ADMIN: 'ADMIN',
  CLINICIAN: 'CLINICIAN',
  RESEARCHER: 'RESEARCHER',
  PARTICIPANT: 'PARTICIPANT'
}

export const INTERVENTION = {
  ACCESS_ENABLED: 'ACCESS_ENABLED'
}

export const NUMBER_TO_WORD = {
  1: 'One',
  2: 'Two',
  3: 'Three'
}

export const UNKNOWN_GROUP = 'UNKNOWN'
export const UNKNOWN_PHASE = 'UNKNOWN'

export const GROUP_OPTIONS = [
  { key: 'Intervention', value: 'INTERVENTION' },
  { key: 'Control', value: 'CONTROL' },
  { key: 'Unassigned', value: 'UNASSIGNED' }
]

export const ASSIGNABLE_GROUP_OPTIONS = [
  { key: 'Intervention', value: 'INTERVENTION' },
  { key: 'Control', value: 'CONTROL' }
]

export const SEX_OPTIONS = [
  { key: 'Female', value: 'F' },
  { key: 'Male', value: 'M' }
]

export const PHASE_OPTIONS = [
  { label: 'Phase Pre Randomisation', value: 'PHASE_PRE_RANDOMISATION' },
  { label: 'Phase 1', value: 'PHASE_1' },
  { label: 'Phase 2', value: 'PHASE_2' },
  { label: 'Phase 3', value: 'PHASE_3' }
]

export const PHASE_STATUS = {
  ENABLED: 'ENABLED',
  NO_ACCESS: 'NO_ACCESS',
  GREY_OUT: 'GREY_OUT'
}

export const PHASES = [
  {
    no: 1,
    label: 'Phase 1',
    value: 'PHASE_1',
    status: PHASE_STATUS.NO_ACCESS,
    img: phase1Img
  },
  {
    no: 2,
    label: 'Phase 2',
    value: 'PHASE_2',
    status: PHASE_STATUS.NO_ACCESS,
    img: phase2Img
  },
  {
    no: 3,
    label: 'Phase 3',
    value: 'PHASE_3',
    status: PHASE_STATUS.NO_ACCESS,
    img: phase3Img
  }
]

export const EXPORT_TYPES = {
  FULL_PARTICIPANT_ALL_CENTERS_EXPORT: 'FULL_PARTICIPANT_ALL_CENTERS_EXPORT',
  FULL_PARTICIPANT_SELECTED_CENTER_EXPORT:
    'FULL_PARTICIPANT_SELECTED_CENTER_EXPORT'
}
