import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'

export const LoadingSpinner = styled(Spinner)`
  color: #f8e7b8;
  width: 3rem;
  height: 3rem;
`

export const LoadingOverlay = styled.div`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #ffffff;
  z-index: 1001;
  opacity: 0.9;
  .loader {
    position: absolute;
    top: 42%;
    left: 44%;
    transform: translate(-42%, -42%);
    color: white;
    background: #ffffff;
    opacity: 1;
    z-index: 1000;
  }
`
