import { map, mapValues } from 'lodash'

/**
 * API base
 */
const base = '/api'

const endpoints = {
  LOGIN: () => `${base}/auth/login`,
  LOGOUT: () => `${base}/auth/logout`,
  USER_PARTICIPANT: () => `${base}/user/participant`,
  QUESTIONNAIRES: () => `${base}/questionnaires`,
  QUESTIONNAIRE_BY_NAME: name => `${base}/questionnaires/name/${name}`,
  QUESTIONNAIRE_RESPONSE_BY_NAME: name =>
    `${base}/questionnaires/responses/${name}`,
  QUESTIONNAIRES_STATUS_BY_PATIENTID: () => `${base}/questionnaires/status`,

  // PARTICIPANT
  CREATE_PARTICIPANT: () => `${base}/user/participant/create`,
  VIEW_PARTICIPANT: id => `${base}/user/participant/${id}`,
  UPDATE_PARTICIPANT: id => `${base}/user/participant/${id}`,
  ASSIGN_PARTICIPANT: (group, id) =>
    `${base}/user/participant/${id}/assign_to_study_group/${group}`,
  PARTICIPANTS: () => `${base}/participants`,
  PARTICIPANT_INTERVENTION_STATUS: id =>
    `${base}/interventions?usernames=${id}`,
  ACTIVATE_INTERVENTION: () => `${base}/interventions/availability`,
  MARK_PHONE_CALL_COMPLETED: (notificationScheduleItemId) => `${base}/notifications/send_notification_for_item/${notificationScheduleItemId}/COMPLETED_PHONE_CALL`,
  INTERVENTIONS_STATUS: () => `${base}/interventions`,
  UPDATE_PHASE: (phase, id) => `${base}/user/participant/${id}/phase/${phase}`,
  REPORT_TYPES: () => `${base}/reports`,
  EXPORT_BY_CENTERS: () =>
    `${base}/reports/FULL_PARTICIPANT_SELECTED_CENTERS_EXPORT`,
  EXPORT_All: () => `${base}/reports/FULL_PARTICIPANT_ALL_CENTERS_EXPORT?`,
  NOTIFICATIONS_STATUS: () => `${base}/notifications/status`,

  // CLINICIAN
  CLINICIANS: () => `${base}/clinicians`
}

// Escape args for all endpoints
export default mapValues(endpoints, f => (...args) =>
  f(...map(args, encodeURIComponent))
)
