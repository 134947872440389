import styled from 'styled-components'
import { Badge } from 'react-bootstrap'

export const SentBadge = styled(Badge)`
  background-color: #09b503;
  color: white;
  border-radius: 10px;
  padding: 0.25em 0.6em;
  font-size: 90%;
`
