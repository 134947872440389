import styled from 'styled-components'
import { Badge } from 'react-bootstrap'

export const BadgeStyled = styled(Badge)`
  background-color: #a7af87;
  color: white;
  border-radius: 10px;
`
export const H2BadgeStyled = styled.h2`
  span {
    padding: 10px 21px;
  }
`
