import { combineReducers } from 'redux'
import participant from 'store/reducers/user'
import questionnaire from 'store/reducers/questionnaire'
import admin from 'store/reducers/admin'
import clinician from 'store/reducers/clinician'

const rootReducer = combineReducers({
  user: participant,
  questionnaire,
  admin,
  clinician
})

export default rootReducer
