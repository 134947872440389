import React from 'react'
import { FormControl } from 'react-bootstrap'
import PropTypes from 'prop-types';

const FormikCustomInput = React.forwardRef(
  ({ onChange, onKeyPress, placeholder, type, label, field, form }, ref) => (
    <FormControl
      {...field}
      ref={ref}
      onChange={changeEvent => {
        form.setFieldValue(field.name, changeEvent.target.value)
        onChange(changeEvent.target.value)
      }}
    />
  )
)
FormikCustomInput.propTypes = {
  form: PropTypes.shape({
    setFieldValue: PropTypes.func
  }),
  field: PropTypes.shape({
    name: PropTypes.string
  })
}
export default FormikCustomInput
