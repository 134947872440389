import * as toasts from 'services/toasts'
import { USER_MSG } from 'constants/messages'
import { api } from 'services/api'
import endpoints from 'constants/endpoints'
import store from 'store'
import axios from 'axios'
import { userActionTypes } from './actionTypes'

const login = credentials => {
  const loginSuccess = data => {
    return {
      type: userActionTypes.LOGIN_SUCCESS,
      data
    }
  }
  const loginFailure = loginError => {
    return {
      type: userActionTypes.LOGIN_FAILURE,
      loginError
    }
  }

  return async dispatch => {
    return axios
      .post(endpoints.LOGIN(), credentials)
      .then(response => {
        store.set('isAuthenticated', true)
        store.set('userData', response.data)
        dispatch(loginSuccess(response.data))
        toasts.success(USER_MSG.LOGIN_SUCCESS, 1000)
      })
      .catch(loginError => {
        store.set('isAuthenticated', false)
        dispatch(loginFailure(loginError.message))
        toasts.error(USER_MSG.LOGIN_FAILED)
      })
  }
}

const getUser = () => {
  const success = data => {
    return {
      type: userActionTypes.USER_SUCCESS,
      data
    }
  }
  const failure = userError => {
    return {
      type: userActionTypes.USER_FAILURE,
      userError
    }
  }

  return async dispatch => {
    return api
      .get(endpoints.USER_PARTICIPANT())
      .then(userRes => {
        // userRes.data.timePoint = TIMEPOINT_KEYS.MONTH_12
        store.set('userData', userRes.data)
        dispatch(success(userRes.data))
      })
      .catch(userError => {
        store.set('userData', {})
        dispatch(failure(userError.message))
        toasts.error(USER_MSG.USER_FAILED)
      })
  }
}

const extendSession = () => {
  const success = () => {
    return {
      type: userActionTypes.SESSION_EXTENDED
    }
  }
  const failure = () => {
    return {
      type: userActionTypes.SESSION_EXPIRED
    }
  }

  return async dispatch => {
    return api
      .post(endpoints.LOGIN(), {})
      .then(() => {
        dispatch(success())
      })
      .catch(() => {
        dispatch(failure())
        store.set('isAuthenticated', false)
        store.set('userData', {})
      })
  }
}

const logout = () => {
  const success = () => {
    return {
      type: userActionTypes.LOGOUT_SUCCESS,
      isFetching: false,
      isAuthenticated: false
    }
  }

  return async dispatch => {
    return api.post(endpoints.LOGOUT(), {}).then(() => {
      dispatch(success())
      store.set('isAuthenticated', false)
      store.set('userData', {})
    })
  }
}

export default {
  login,
  logout,
  extendSession,
  getUser
}
