import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import userActions from 'store/actions/userActions'
import { ROLE, ROUTES } from 'constants/consts'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import Modal from 'react-bootstrap/Modal'
import ModalHeaderStyled from 'components/ModalHeader'
import ModalBodyStyled from 'components/ModalBodyStyled'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import ButtonStyled from 'components/ButtonStyled'
import newLandingImg from 'assets/images/designs/Landing-Page-Small-Rug-2.jpg'
import adminActions from 'store/actions/adminActions'
import { Col, Container, Row } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify';

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isAuthenticated = useSelector(state => state.user.isAuthenticated)
  const userData = useSelector(state => state.user.data)
  const [openLoginModal, setOpenLoginModal] = useState(false)

  useEffect(() => {
    if (!isAuthenticated) return
    if (isEmpty(userData)) return
    if (userData.role === ROLE.PARTICIPANT) {
      dispatch(userActions.getUser()).then(() => {
        dispatch(adminActions.fetchInterventionsStatus(userData.username)).then(
          () => {
            navigate(ROUTES.PARTICIPANT_DASHBOARD, { replace: true })
          }
        )
      })
    } else {
      navigate(ROUTES.ADMIN_DASHBOARD, { replace: true })
    }
  }, [isAuthenticated, userData, dispatch, navigate])

  const handleLogin = formData => {
    dispatch(userActions.login(formData))
  }

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string()
      .min(3, 'Password must be 3 characters at minimum')
      .required('Password is required')
  })

  const handleOpenLoginModal = () => {
    setOpenLoginModal(true)
  }
  const handleCloseLoginModal = () => {
    setOpenLoginModal(false)
  }

  return (
    <Container
      fluid
      className="landing-container d-flex justify-content-center align-items-center pl-0 pr-0"
    >
      <Row>
        <Col className="d-flex justify-content-center align-items-center">
          <img
            src={newLandingImg}
            alt="Mother sitting and playing with baby"
            width="64%"
            className="landing-img"
          />
        </Col>
        <Col
          sm={12}
          md={12}
          className="d-flex justify-content-center text-center"
        >
          <ButtonStyled
            onClick={handleOpenLoginModal}
            className="login-btn d-block"
          >
            START
          </ButtonStyled>
        </Col>
      </Row>
      <Modal
        show={openLoginModal}
        size="lg"
        className="login-modal"
        onHide={handleCloseLoginModal}
      >
        <ModalHeaderStyled closeButton>
          <Modal.Title>Login</Modal.Title>
        </ModalHeaderStyled>
        <ModalBodyStyled>
          <div className="page-wrapper p-4">
            <div className="text-center mb-3">
              <h6>Enter your Username and Password </h6>
            </div>
            <Formik
              initialValues={{ username: '', password: '' }}
              validationSchema={LoginSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false)
                handleLogin(values)
              }}
            >
              {({ touched, errors, isSubmitting }) => (
                <Form className="theme-form">
                  <div className="form-group">
                    <Field
                      name="username"
                      placeholder="Enter username"
                      autoComplete="username"
                      className={`form-control ${
                        touched.username && errors.username ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="username"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group">
                    <Field
                      type="password"
                      name="password"
                      placeholder="Enter password"
                      autoComplete="current-password"
                      className={`form-control ${
                        touched.password && errors.password ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="password"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group form-row mt-3 mb-0">
                    <ButtonStyled
                      type="submit"
                      disabled={isSubmitting}
                      className="btn-block"
                    >
                      {isSubmitting ? 'Please wait...' : 'LOGIN'}
                    </ButtonStyled>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBodyStyled>
      </Modal>
      <ToastContainer />
    </Container>
  )
}

export default Login
