import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import questionnaireAction from 'store/actions/questionnaireActions'
import Form from '@rjsf/core'
import QuestionnaireBotBar from 'components/forms/QuestionnaireBotBar'
import Modal from 'react-bootstrap/Modal'
import Stepper from 'react-stepper-horizontal'
import { find, isString, map, toString } from 'lodash'
import ModalHeaderStyled from 'components/ModalHeader'
import * as api from 'services/api'
import ModalBodyStyled from 'components/ModalBodyStyled'
import { sectionDesc } from 'services/questionnaire'
import * as toasts from 'services/toasts'
import { API_MSG } from 'constants/messages'
import { TIMEPOINT } from 'constants/consts'

const QuestionnaireModal = () => {
  const dispatch = useDispatch()
  const [activeStep, setActiveStep] = useState(0)
  const [areQuestionnairesInTimepointFinalised, setAreQuestionnairesInTimepointFinalised] = useState(false)
  const [response, setResponse] = useState({})
  const open = useSelector(state => state.questionnaire.open)
  const result = useSelector(state => state.questionnaire.result)
  const schemas = useSelector(state => state.questionnaire.schemas)
  const questionnaireCompletionCallback = useSelector(state => state.questionnaire.completionCallback)
  const steps = map(schemas, 'step')
  const tedipremId = useSelector(state => state.questionnaire.tedipremId)
  const userData = useSelector(state => state.user.data)
  const questionnaireType = useSelector(
    state => state.questionnaire.questionnaireType
  )
  const myRef = useRef(null)

  useEffect(() => {
    setActiveStep(activeStep)
  }, [activeStep])

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleClose = () => {
    setActiveStep(0)
    dispatch(questionnaireAction.hide())
  }

  const onSubmit = ({ formData }) => {
    setResponse({ ...response, ...formData })
    myRef.current.scrollTop = 0
    if (activeStep !== steps.length - 1)
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    else {
      const filledForm = {
        patientId: {
          centerId: tedipremId.split('-')[0],
          pid: tedipremId.split('-')[1]
        },
        questionnaireName: questionnaireType.name,
        questionnaireTimepoint: TIMEPOINT(
          userData.timepoint,
          userData.patientIds.length
        ).name,
        responses: []
      }

      map(formData, (value, key) => {
        const question = find(result, param => {
          return param.parameterName === key
        })
        if (question) {
          filledForm.responses.push({
            parameterId: question.parameterId,
            values: [isString(value) ? value : toString(value)]
          })
        }
      })

      api
        .submitQuestionnaireResponse(questionnaireType.name, filledForm)
        .then((response) => {
          toasts.success(API_MSG.FORM_SUBMITTED_SUCCESS, 5000)

          if (response.allQuestionnairesInTimepointFinalised) {
            // Use the modal to display the completion text
            setAreQuestionnairesInTimepointFinalised(true);

            // Do not automatically hide. Allow the user to close the modal manually
          } else {
            // Auto-hide the modal so the user does not need to manually close it
            setActiveStep(0)
            dispatch(questionnaireAction.hide())
          }

          // Notify the upstream component that a questionnaire has been submitted
          if (questionnaireCompletionCallback != null) {
            questionnaireCompletionCallback();
          }
        })
    }
  }

  const getStepContent = stepIndex => {
    const CustomTitleField = props => {
      const { title } = props
      if (title === 'sectionHelp') {
        return (
          <div className="mt-3">
            {sectionDesc({
              questionnaire: questionnaireType,
              timepoint: userData.timepoint,
              childNum: userData.patientIds.length,
              sectionOrder: stepIndex + 1
            })}
          </div>
        )
      }
    }

    const fields = {
      TitleField: CustomTitleField
    }
    //   fields = {...fields, schemas[stepIndex].fields}
    return schemas && schemas.length !== 0 && schemas[stepIndex] ? (
      <Form
        // fields={schemas[stepIndex].fields ? schemas[stepIndex].fields : {}}
        widgets={schemas[stepIndex].widgets ? schemas[stepIndex].widgets : {}}
        schema={schemas[stepIndex].schema}
        uiSchema={schemas[stepIndex].uiSchema || {}}
        onSubmit={onSubmit}
        formData={response}
        fields={fields}
      >
        <QuestionnaireBotBar
          activeStep={activeStep}
          stepsLength={steps.length}
          onBack={handleBack}
        />
      </Form>
    ) : null
  }

  const getCompletionContent = () => {
    return (
      <div>{
        TIMEPOINT(
          userData.timepoint,
          userData.patientIds.length
        ).completionText
      }</div>
    )
  }

  return (
    <>
      <Modal
        scrollable
        show={open}
        onHide={handleClose}
        size="lg"
        backdrop="static"
      >
        <ModalHeaderStyled closeButton>
          <Modal.Title>
            {' '}
            {questionnaireType && questionnaireType.title}{' '}
          </Modal.Title>
        </ModalHeaderStyled>
        <ModalBodyStyled ref={myRef}>
          <div className="stepper">
            <Stepper
              activeColor="#e0937f"
              completeColor="#e0937f"
              className="stepper"
              steps={steps}
              activeStep={activeStep}
            />
          </div>
          <div id="questionnaire-modal" className="formCls">
            {areQuestionnairesInTimepointFinalised ? (
              getCompletionContent()
            ) : (
              getStepContent(activeStep)
            )}
          </div>
        </ModalBodyStyled>
      </Modal>
    </>
  )
}

QuestionnaireModal.propTypes = {
  viewStateManager: PropTypes.shape({
    setLoading: PropTypes.func,
    setReadyDelayed: PropTypes.func
  })
}
export default QuestionnaireModal
