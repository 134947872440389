/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { SESSION } from 'constants/consts';
import userActions from 'store/actions/userActions';
import { useDispatch } from 'react-redux';

const AutoLagoutTimer = props => {
  const { ComposedClass } = props
  const [idle, setIdle] = useState(false)
  const dispatch = useDispatch()
  
  useEffect(() => {
    let interval;
    setTimeout(() => {
      interval = setInterval(() => {
        if (!idle) {
          dispatch(userActions.extendSession())
        }
      }, 1000 * 60 * SESSION.ActivityIntervalAuth);
    }, 1000);
    return () => clearInterval(interval);
  }, [idle])

  const handleOnIdle = event => {
    setIdle(true)
  }

  const handleOnActive = event => {
    setIdle(false)
  }

  const { isIdle } = useIdleTimer({
    timeout: 1000 * 60 * SESSION.ActivityIntervalAuth,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500
  })

  return (
    <div>
      <ComposedClass />
    </div>
  )
}

export default AutoLagoutTimer
