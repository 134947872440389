import styled from 'styled-components'
import ButtonLink from 'components/ButtonLink'

const ButtonLinkStyled = styled(ButtonLink)``

const TableContainer = styled.div`
  table {
    width: 100%;
    border: none;
  }
  th {
    font-weight: 400;
    border-top: none;
    padding: 6px;
    text-align: left;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 0.9rem;
    color: #2d4873;
  }
  thead {
    border-top: none;
  }
  td {
    padding: 6px;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #ffffff;
    text-align: left;
    font-size: 0.9rem;
    //:last-child {
    //  padding-left: 30px;
    //}
    ${ButtonLinkStyled} {
      font-size: 0.9rem !important;
      color: #588785 !important;
      text-decoration: underline;
    }
  }

  @media (max-width: 760px) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
      :last-child {
        padding-left: 50%;
      }
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 65%;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: bold;
    }

    td:nth-of-type(1):before {
      content: 'Parent ID';
    }
    td:nth-of-type(2):before {
      content: 'Timepoint';
    }
    td:nth-of-type(3):before {
      content: 'Child ID';
    }
    td:nth-of-type(4):before {
      content: 'Children Num';
    }
    td:nth-of-type(5):before {
      content: 'Phase';
    }
    td:nth-of-type(6):before {
      content: 'Group';
    }
    td:nth-of-type(7):before {
      content: 'First Reminder';
    }
    td:nth-of-type(8):before {
      content: 'Second Reminder';
    }
    td:nth-of-type(9):before {
      content: 'Third Reminder';
    }
  }
`
export default TableContainer
