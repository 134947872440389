import styled from 'styled-components'
import ModalHeader from 'react-bootstrap/ModalHeader'

const ModalHeaderStyled = styled(ModalHeader)`
  background-color: #f9edd7;
  padding: 0.7rem 1rem 0.7rem 1rem;
  font-size: 1.3rem !important;
  .close {
    padding-top: 1.1rem;
  }
`

export default ModalHeaderStyled
