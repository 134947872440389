import styled from 'styled-components'
import { CheckCircle } from '@styled-icons/fa-solid/CheckCircle'

const CompleteIcon = styled(CheckCircle)`
  color: ${props => (props.iscompleted ? 'white' : '#259922')};
  border: ${props => props.iscompleted && '1px solid #d2d2d2'};
  border-radius: ${props => props.isCompleted && '50%'};
`

export default CompleteIcon
