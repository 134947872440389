/* eslint-disable react/destructuring-assignment,react/no-unescaped-entities */
import {
  chain,
  groupBy,
  includes,
  isEmpty,
  map,
  sortBy,
  toNumber
} from 'lodash'
import {
  PATIENT_DEPENDENTS,
  QUESTIONNAIRES_KEYS,
  QUESTIONNAIRES_SECTIONS,
  TIMEPOINT_KEYS
} from 'constants/consts'
import React from 'react'
import OlStyled from 'components/OlStyled'
import PStyled from 'components/PStyled'
import RangeSlider from 'react-bootstrap-range-slider'
import playingToysImg from 'assets/images/designs/new/playing with toys - 100px.png'
import sittingThemselvesImg from 'assets/images/designs/new/sitting by myself - 100px.jpg'
import supportedStandingImg from 'assets/images/designs/new/supported standing - 100px.png'
import tummyCrawlingImg from 'assets/images/designs/new/tummy time_crawling - 100px.png'
import bouncerRocker6To12Img from 'assets/images/designs/new/bouncer_rocker 6-12 - 100px.png'
import listeningReadingImg from 'assets/images/designs/new/listening and enjoying reading - 100px.png'
import bouncerRocker0To6Img from 'assets/images/designs/new/bouncer_rocker 0-6 - 100px.png'
import kickingImg from 'assets/images/designs/new/kicking play - 100px.png'
import tummyImg from 'assets/images/designs/new/tummy time - 100px.png'
import watchingPeopleToysImg from 'assets/images/designs/new/watching people and toys - 100px.png'

const sectionId = QUESTIONNAIRES_SECTIONS.SECTION_ID
const orderOfSection = QUESTIONNAIRES_SECTIONS.ORDER_OF_SECTION
const orderWithinSection = QUESTIONNAIRES_SECTIONS.ORDER_WITHIN_SECTION

export function sectionDesc(props) {
  const { questionnaire, timepoint, sectionOrder } = props
  let babyStr = PATIENT_DEPENDENTS.BABY
  if (
    timepoint !== TIMEPOINT_KEYS.BASELINE &&
    timepoint !== TIMEPOINT_KEYS.MONTH_3 &&
    timepoint !== TIMEPOINT_KEYS.MONTH_6
  )
    babyStr = PATIENT_DEPENDENTS.CHILD

  let description = ''
  switch (questionnaire.key) {
    case QUESTIONNAIRES_KEYS.TANDI:
      if (sectionOrder === 1) {
        description = (
          <>
            <PStyled>
              The purpose of this questionnaire is to explore how you would rate
              the health status of the {babyStr}
              <br />
              Describing the {babyStr}’s health <b>TODAY</b>
              <br />
              Under each heading, select one box that best describes how you
              would describe the health state of the {babyStr} <b>TODAY</b>.
            </PStyled>
          </>
        )
      } else if (sectionOrder === 3) {
        description = (
          <>
            <PStyled>
              We would like to know how good or bad you think the {babyStr}’s
              health is TODAY. This line is numbered 0 to 100. 100 means the
              best health you can imagine. 0 means the worst health you can
              imagine. Please move the sliding bar to the number that shows how
              good or bad you think the {babyStr}’s health is TODAY.
            </PStyled>
          </>
        )
      }
      return description

    case QUESTIONNAIRES_KEYS.THERAPY:
      if (sectionOrder === 1) {
        description = (
          <>
            <PStyled>
              TEDI-Prem would like to check in and see if your {babyStr}{' '}
              received any of the following therapy services in the last month
            </PStyled>
          </>
        )
      }
      return description

    case QUESTIONNAIRES_KEYS.PLAY:
      if (sectionOrder === 1) {
        description = (
          <>
            <PStyled>
              Choose one day over the past week that best represents a usual day
              with you and your {babyStr} (e.g. not at childcare). If you didn’t
              get a chance to complete an activity, please enter 0. <br /><br />
              <p style={{ fontSize: 16, fontWeight: 'bold' }}>
                {' '}
                How long did you and your {babyStr} spend:
              </p>
            </PStyled>
          </>
        )
      }
      return description

    case QUESTIONNAIRES_KEYS.KPSE:
      if (sectionOrder === 1) {
        description = (
          <>
            <PStyled>
              This scale has 15 items about a range of different parenting
              behaviours. Please select the answer that comes closest to how you
              generally feel. Here is an example already completed:
            </PStyled>
            <PStyled>
              e.g. I am confident about holding my {babyStr}
              <br />
              ☐No, hardly ever <br />
              ☐No, not very often <br />
              ☒Yes, some of the time <br />
              ☐Yes, most of the time <br />
            </PStyled>
            <PStyled>
              This selection would mean “I feel confident about holding my{' '}
              {babyStr} some of the time” <br /> Please complete the other
              questions in the same way.
            </PStyled>
          </>
        )
      }
      return description
    case QUESTIONNAIRES_KEYS.SOCIO_DEMOGRAPHIC:
      if (sectionOrder === 1) {
        switch (timepoint) {
          case TIMEPOINT_KEYS.BASELINE: {
            description = (
              <PStyled>
                We'd like to start by asking some questions about you and your
                family.
              </PStyled>
            );
            break;
          }
          case TIMEPOINT_KEYS.MONTH_12: {
            description = (
              <PStyled>
                You may remember completing some of these questions when you first joined TEDI-Prem however now that
                your child is 1 year of age, some of your responses may have changed. Thanks for taking the time to
                answer these questions.
              </PStyled>
            );
            break;
          }
          case TIMEPOINT_KEYS.MONTH_24: {
            description = (
              <PStyled>
                You may remember completing some of these questions when you first joined TEDI-Prem however now that
                your child is 2 years of age, some of your responses may have changed. Thanks for taking the time to
                answer these questions.
              </PStyled>
            );
            break;
          }
          default: {
            // Unexpected case
            description = (
              <PStyled>
              </PStyled>
            );
            break;
          }
        }
      }
      return description

    case QUESTIONNAIRES_KEYS.DASS21:
      if (sectionOrder === 1) {
        description = (
          <>
            <PStyled>
              Please read each statement and select a number 0, 1, 2 or 3 which
              indicates how much the statement applied to you over the past
              week. There are no right or wrong answers. Do not spend too much
              time on any statement.
            </PStyled>
            <PStyled>
              The rating scale is as follows:
              <OlStyled>
                <li>Did not apply to me at all</li>
                <li>Applied to me to some degree, or some of the time</li>
                <li>
                  Applied to me to a considerable degree, or a good part of time
                </li>
                <li>Applied to me very much, or most of the time</li>
              </OlStyled>
            </PStyled>
          </>
        )
      }
      return description

    case QUESTIONNAIRES_KEYS.SF_6D:
      if (sectionOrder === 2) {
        description = (
          <PStyled>
            The following questions are about activities you might do during a
            typical day. Does your health now limit you in these activities? If
            so, how much?
          </PStyled>
        )
      } else if (sectionOrder === 3) {
        description = (
          <PStyled>
            During the past 4 weeks, how much of the time have you had any of
            the following problems with your work or other regular daily
            activities as a result of your physical health?
          </PStyled>
        )
      } else if (sectionOrder === 4) {
        description = (
          <PStyled>
            During the past 4 weeks, how much of the time have you had any of
            the following problems with your work or other regular daily
            activities as a result of any emotional problems (such as feeling
            depressed or anxious)?
          </PStyled>
        )
      } else if (sectionOrder === 6) {
        description = (
          <PStyled>
            These questions are about how you feel and how things have been with
            you during the past 4 weeks. For each question, please give the one
            answer that comes closest to the way you have been feeling. How much
            of the time during the past 4 weeks?
          </PStyled>
        )
      }
      return description
    default:
      return ''
  }
}

export function generateSchema(questionnaireType, params, timepoint) {
  const dependencies = {}
  const required = []
  const uiOrder = []
  const properties = {}
  let uiSchema = {}

  map(params, param => {
    if (!(param.enableWhen && !isEmpty(param.enableWhen))) {
      uiOrder.push(param.parameterName)
      if (!param.parameterOptional) required.push(param.parameterName)

      properties[param.parameterName] = {
        format: param.parameterType,
        title: param.parameterLabel,
        type: param.parameterType === 'NUMBER' ? 'number' : 'string',
        default: param.parameterName === 'health_today' && 50
      }

      if (param.parameterType === 'MENU' && param.menu) {
        uiSchema[param.parameterName] = {
          'ui:widget': 'radio'
        }
        properties[param.parameterName].enum = param.menu.menuOptions.map(m => {
          if (m.optionName === 'Yes, part time (&lt; 30 hours a week)')
            return '< 30 Hours a Week'
          return m.optionName
        })
      }

      if (param.parameterType === 'DATE') {
        uiSchema[param.parameterName] = {
          'ui:widget': 'date'
        }
      }
    }

    const depParams = param.subParameters
    if (depParams && isEmpty(depParams)) return
    if (!param.menu) return
    dependencies[param.parameterName] = {
      oneOf: []
    }
    map(param.menu.menuOptions, option => {
      const depProperties = {}
      const depRequired = []
      depProperties[param.parameterName] = {
        enum: [option.optionName]
      }
      map(depParams, depParam => {
        if (includes(map(depParam.enableWhen, 'value'), option.optionName)) {
          depProperties[depParam.parameterName] = {
            format: depParam.parameterType,
            title: depParam.parameterLabel,
            type: 'string'
          }
          if (depParam.parameterType === 'MENU' && depParam.menu) {
            uiSchema[depParam.parameterName] = {
              'ui:widget': 'radio'
            }
            depProperties[
              depParam.parameterName
            ].enum = depParam.menu.menuOptions.map(m => m.optionName)
          }
          if (!includes(uiOrder, depParam.parameterName))
            uiOrder.push(depParam.parameterName)

          depRequired.push(depParam.parameterName)
        }
      })
      dependencies[param.parameterName].oneOf.push({
        properties: depProperties,
        required: depRequired
      })
    })
  })

  properties.sectionHelp = {
    type: 'object'
  }

  uiOrder.unshift('sectionHelp')
  const schema = {
    type: 'object',
    description: '',
    properties,
    required,
    dependencies
  }

  const CustomRange = fieldProps => {
    return (
      <div>
        <div className="slider-range-label-left">
          <span className="worse-health">0</span>
          <span className="best-health">100</span>
        </div>
        <div className="slider-container">
          <div className="slider">
            <RangeSlider
              tooltip="on"
              tooltipPlacement="top"
              value={fieldProps.value}
              onChange={event => fieldProps.onChange(event.target.value)}
            />
          </div>
        </div>
        <div className="slider-range-label-right">
          <span className="worse-health">Worse Health</span>
          <span className="best-health">Best Health</span>
        </div>
      </div>
    )
  }

  const PlayWidget = fieldProps => {
    let imgSrc
    if (includes(fieldProps.id, 'kicking')) {
      imgSrc = kickingImg
    } else if (includes(fieldProps.id, 'tummy_time_crawling')) {
      imgSrc = tummyCrawlingImg
    } else if (includes(fieldProps.id, 'bouncer')) {
      if (
        timepoint === TIMEPOINT_KEYS.BASELINE ||
        timepoint === TIMEPOINT_KEYS.MONTH_3 ||
        timepoint === TIMEPOINT_KEYS.MONTH_6
      )
        imgSrc = bouncerRocker0To6Img
      else
        imgSrc = bouncerRocker6To12Img
    } else if (includes(fieldProps.id, 'tummy_time')) {
      imgSrc = tummyImg
    } else if (includes(fieldProps.id, 'playing_toys')) {
      imgSrc = playingToysImg
    } else if (includes(fieldProps.id, 'sitting_themselves')) {
      imgSrc = sittingThemselvesImg
    } else if (includes(fieldProps.id, 'using_equipment')) {
      imgSrc = supportedStandingImg
    } else if (includes(fieldProps.id, 'watching_people_toys')) {
      imgSrc = watchingPeopleToysImg
    } else if (includes(fieldProps.id, 'holding_head_up')) {
      imgSrc = kickingImg // no image
    } else if (includes(fieldProps.id, 'listening_reading')) {
      imgSrc = listeningReadingImg
    }
    // TODO Derive min/max from parameter database
    return (
      <div className="input-group play-field">
        <input
          type="number"
          className="form-control"
          placeholder="Enter Minutes"
          value={fieldProps.value}
          onChange={event => fieldProps.onChange(event.target.value)}
          style={{ height: '35px', marginTop: '0', marginRight: '17px' }}
          min="0"
          max="1440"
        />
        <img
          alt=""
          className="input-group-btn"
          src={imgSrc}
          width="100"
          style={{ marginTop: '-82px' }}
        />
      </div>
    )
  }

  const widgets = {
    RangeWidget: CustomRange
  }

  uiSchema = {
    ...uiSchema,
    'ui:order': uiOrder,
    // watching_people_toys: {
    //   'ui:placeholder': 'Enter Minutes'
    // },
    // tummy_time: { 'ui:placeholder': 'Enter Minutes' },
    // holding_head_up: { 'ui:placeholder': 'Enter Minutes' },
    // kicking: { 'ui:placeholder': 'Enter Minutes' },
    // health_today: { 'ui:widget': 'range' },
    // listening_reading: {
    //   'ui:placeholder': 'Enter Minutes'
    // },
    // tummy_time_crawling: { 'ui:placeholder': 'Enter Minutes' },
    // sitting_themselves: { 'ui:placeholder': 'Enter Minutes' },
    // playing_toys: { 'ui:placeholder': 'Enter Minutes' },
    // sitting_bouncer: { 'ui:placeholder': 'Enter Minutes' },
    // using_equipment: { 'ui:placeholder': 'Enter Minutes' }

    listening_reading: { 'ui:widget': PlayWidget },
    tummy_time_crawling: { 'ui:widget': PlayWidget },
    sitting_themselves: { 'ui:widget': PlayWidget },
    playing_toys: { 'ui:widget': PlayWidget },
    sitting_bouncer: { 'ui:widget': PlayWidget },
    using_equipment: { 'ui:widget': PlayWidget },
    watching_people_toys: { 'ui:widget': PlayWidget },
    tummy_time: { 'ui:widget': PlayWidget },
    holding_head_up: { 'ui:widget': PlayWidget },
    kicking: { 'ui:widget': PlayWidget }
  }

  return {
    widgets,
    schema,
    uiSchema,
    step: params[0][orderOfSection].toString()
  }
}

export function groupQuestions(questions) {
  return groupBy(questions, sectionId)
}

export function orderSections(sections) {
  const orderedSections = chain(sections)
    .toPairs()
    .sort(
      (a, b) =>
        toNumber(a[1][0][orderOfSection]) - toNumber(b[1][0][orderOfSection])
    )
    .fromPairs()
    .value()
  return orderedSections
}

export function orderQuestions(questions) {
  return sortBy(questions, orderWithinSection)
}
