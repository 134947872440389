import styled from 'styled-components'
import { Container } from 'react-bootstrap'

const ChildContainer = styled(Container)`
  padding: 15px 20px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;

  div {
    margin-bottom: 7px;
  }
`

export default ChildContainer
