import { toast } from 'react-toastify'

/**
 * Toast Options (ms)
 */
const globalOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
}

/**
 * Pop up a success toast
 * @param {string} message
 */
export function success(message, timeout) {
  const options = {
    ...globalOptions,
    autoClose: timeout
  }
  toast.success(message, options)
}

/**
 * Pop up a info toast
 * @param {string} message
 */
export function info(message) {
  toast.info(message, globalOptions)
}

/**
 * Pop up a warning toast
 * @param {string} message
 * @param {number} timeout
 */
export function warning(message) {
  toast.warn(message, globalOptions)
}

/**
 * Pop up a error toast
 * @param {string} message
 */
export function error(message, timeout) {
  const options = {
    ...globalOptions,
    autoClose: timeout
  }
  toast.warn(message, options)
}
