import { QUESTIONNAIRES, QUESTIONNAIRES_KEYS } from 'constants/consts'
import { includes, keys, map } from 'lodash'

const fetchQuestionnaires = (timepoint, childNum) => {
  const questionnaires = []
  const keyList = keys(QUESTIONNAIRES_KEYS)
  let i = 1
  let questionnaire = {}
  map(keyList, key => {
    questionnaire = QUESTIONNAIRES(key, timepoint, childNum)
    if (includes(questionnaire.timePoints, timepoint)) {
      questionnaire.id = i++
      questionnaires.push(questionnaire)
    }
  })
  return questionnaires
}

export default fetchQuestionnaires
