/* eslint-disable no-param-reassign */
import React from 'react'
import { Field, Form, Formik } from 'formik'
import { Col, Row } from 'react-bootstrap'
import {
  PopoverButtonStyled,
  PopoverFormCheckStyled,
  PopoverHeaderStyled,
  PopoverStyled
} from 'components/PopoverStyled'
import adminActions from 'store/actions/adminActions'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { ASSIGNABLE_GROUP_OPTIONS } from 'constants/consts';

const AssignmentPopover = React.forwardRef(({ ...props }, ref) => {
  const dispatch = useDispatch()
  const { cell, value } = props

  const handleAssign = formData => {
    dispatch(
      adminActions.assign(formData.group, cell.row.values.username)
    ).then(() =>
      setTimeout(() => {
        document.body.click()
      }, 100)
    )
  }

  return (
    <PopoverStyled ref={ref} content {...props}>
      <PopoverHeaderStyled>
        Group Assignment for Participant {cell.row.values.username}
      </PopoverHeaderStyled>

      <Formik
        initialValues={{
          group: undefined
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false)
          handleAssign(values)
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form>
            {/* Group */}
            <Row>
              <Col sm={12} className="text-center my-3">
                <Field name="group">
                  {({ field }) => {
                    return (
                      <>
                        {ASSIGNABLE_GROUP_OPTIONS.map(option => {
                          return (
                            <PopoverFormCheckStyled
                              inline
                              {...field}
                              type="radio"
                              label={option.key}
                              value={option.value}
                              defaultChecked={option.value === value}
                            />
                          )
                        })}
                      </>
                    )
                  }}
                </Field>
              </Col>
              <Col sm={12} className="text-center mb-2">
                <PopoverButtonStyled
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Please wait...' : 'Assign'}
                </PopoverButtonStyled>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </PopoverStyled>
  )
})

AssignmentPopover.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      values: PropTypes.shape({
        username: PropTypes.string
      })
    })
  }),
  value: PropTypes.string
}
export default AssignmentPopover
