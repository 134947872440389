import React, { useState } from 'react'
import * as toast from 'services/toasts'
import { LoadingOverlay, LoadingSpinner } from 'components/LoadingSpinner'

/**
 * View state enums
 */
const viewStates = {
  INIT: 'INIT',
  LOADING: 'LOADING',
  READY: 'READY',
  ERROR: 'ERROR'
}

export const loadingHOC = WrappedComponent => {
  function HOC(props) {
    const [errorMessage, setErrorMessage] = useState(undefined)
    const [viewState, setViewState] = useState(viewStates.INIT)
    const viewStateManager = {
      // View state getters
      getState: () => viewState,
      getErrorMessage: () => errorMessage,
      isInit: () => viewState === viewStates.INIT,
      isLoading: () => viewStates.LOADING,
      isReady: () => viewStates.READY,
      isError: () => viewState === viewStates.ERROR,

      // View state setters
      setInit: () => setViewState(viewStates.INIT),
      setLoading: () => setViewState(viewStates.LOADING),
      setReady: () => setViewState(viewStates.READY),
      setReadyDelayed: () =>
        setTimeout(() => setViewState(viewStates.READY), 1500),
      setError: message => {
        toast.error(message)
        setViewState(viewStates.ERROR)
        setErrorMessage(message)
      }
    }
    return (
      <>
        {viewState === viewStates.LOADING && (
          <LoadingOverlay>
            <div className="loader">
              <LoadingSpinner animation="grow" />
            </div>
          </LoadingOverlay>
        )}
        <WrappedComponent {...props} viewStateManager={viewStateManager} />
      </>
    )
  }
  return HOC
}

export default loadingHOC
