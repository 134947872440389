import React, { useEffect, useState } from 'react'
import { ADMIN_MENUITEMS, PARTICIPANT_MENUITEMS } from 'constants/menu'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'
import UserPanel from 'layouts/DashboardLayout/userPanel'
import logo from 'assets/images/designs/TEDI-Prem logo_final.png'
import logoCompact from 'assets/images/designs/TEDI-Prem logo_final small.png'
import LISidebar from 'components/LISidebar'
import { useDispatch, useSelector } from 'react-redux'
import { map } from 'lodash'
import { QUESTIONNAIRE_PLAY, ROLE, TIMEPOINT } from 'constants/consts'
import { IconWrapper24 } from 'components/icons/styles'
import { CloseButton, Col, Media } from 'react-bootstrap'
import playImg from 'assets/images/designs/play-questionnaire-icon.png'
import { DashboardButton } from 'components/Dashboard'
import { SidebarSection } from 'components/Sidebar'
import { getQuestionnaireByName } from 'services/api'
import {
  generateSchema,
  groupQuestions,
  orderQuestions,
  orderSections
} from 'services/questionnaire'
import questionnaireAction from 'store/actions/questionnaireActions'
import ChildrenSelect from 'components/forms/ChildrenSelect'
import Modal from 'react-bootstrap/Modal'

const Sidebar = props => {
  const dispatch = useDispatch()
  const isMax990 = useMediaQuery({ query: '(max-width: 990px)' })
  const isMin990 = useMediaQuery({ query: '(min-width: 990px)' })
  const userData = useSelector(state => state.user.data)
  const [menuItems, setMenuItems] = useState([])
  const [openChildSelectModal, setOpenChildSelectModal] = useState(false)
  let tedipremId = useSelector(
    state => state.user.data.primaryId && state.user.data.primaryId.patientId
  )
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState({})

  useEffect(() => {
    const { sidebar, openCloseSidebar } = props
    if (isMax990 && !sidebar) openCloseSidebar()
    if (isMin990 && sidebar) openCloseSidebar()

    if (userData.role === ROLE.PARTICIPANT) {
      const items = map(PARTICIPANT_MENUITEMS, item => {
        if (
          item.title ===
          TIMEPOINT(userData.timepoint, userData.patientIds.length).name
        ) {
          item.disabled = false
        }
        return item
      })
      setMenuItems(items)
    } else {
      setMenuItems(ADMIN_MENUITEMS)
    }
  }, [props, isMax990, isMin990, userData.role, userData.timepoint])

  const openSelectChildModal = () => {
    setOpenChildSelectModal(true)
  }

  const closeSelectChildModal = () => {
    setOpenChildSelectModal(false)
  }

  const displayForm = async quest => {
    const newQues = !quest ? selectedQuestionnaire : quest
    const { name } = newQues
    const schemas = []
    const steps = []
    let schema = {}

    const result = await getQuestionnaireByName(name)
    const sections = groupQuestions(result)
    const orderedSections = orderSections(sections)
    map(orderedSections, sectionParams => {
      const orderedQuestions = orderQuestions(sectionParams)
      schema = generateSchema(name, orderedQuestions)
      schemas.push(schema)
    })

    const lastPage = {
      description:
        'All steps completed. Thanks for participating in this questionnaire! Please click the Submit button to finalise the form.',
      type: 'object',
      properties: {
        secret: {
          type: 'string',
          default: "I'm a hidden string."
        }
      }
    }
    const lastPageUiSchema = {
      secret: {
        'ui:widget': 'hidden'
      }
    }
    schemas.push({
      step: schemas.length.toString(),
      schema: lastPage,
      uiSchema: lastPageUiSchema
    })

    dispatch(
      questionnaireAction.show(newQues, result, schemas, steps, tedipremId, null)
    )
  }

  const handleSelectChild = formData => {
    if (formData) tedipremId = formData.child
    setOpenChildSelectModal(false)
    displayForm()
  }

  const handleStart = (questionnaire, e) => {
    e.stopPropagation()
    setSelectedQuestionnaire(questionnaire)
    if (questionnaire.perChild && userData.patientIds.length > 1) {
      openSelectChildModal()
    } else {
      displayForm(questionnaire)
    }
  }

  return (
    <>
      <div className="page-sidebar">
        <div className="sidebar-close-btn d-lg-none">
          <div className="nav-right col p-0">
            <IconWrapper24>
              <CloseButton onClick={props.openCloseSidebar} />
            </IconWrapper24>
          </div>
        </div>
        <div className="main-header-left">
          <div className="logo-wrapper compactLogo">
            <Link to="/dashboard/default">
              <img className="" src={logoCompact} alt="" />
              <img className="compactImg" src={logo} alt="" />
            </Link>
          </div>
        </div>
        <div className="c custom-scrollbar text-center">
        {userData.role === ROLE.PARTICIPANT && (
          <UserPanel />
        )}
          <ul className="sidebar-menu justify-content-center" id="myDIV">
            {menuItems.map(menuItem => (
              <LISidebar disabled={menuItem.disabled} key={menuItem.title}>
                {menuItem.sidebartitle ? (
                  <div className="sidebar-title">{menuItem.sidebartitle}</div>
                ) : (
                  ''
                )}
                {menuItem.type === 'link' ? (
                  <Link
                    to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                    className={`sidebar-header ${
                      menuItem.active ? 'active' : ''
                    }`}
                  >
                    <span className="sidebar-span">{menuItem.title}</span>
                    {menuItem.children ? (
                      <i className="fa fa-angle-right pull-right" />
                    ) : (
                      ''
                    )}
                  </Link>
                ) : (
                  ''
                )}
              </LISidebar>
            ))}
          </ul>
        </div>
        {userData.role === ROLE.PARTICIPANT && (
          <SidebarSection>
            <Col className="d-flex justify-content-center align-items-center">
              <Media className="d-flex justify-content-center align-items-center">
                {/* <Media.Body> */}
                {/*  {showPlayDesc && */}
                {/*    QUESTIONNAIRE_PLAY( */}
                {/*      userData.timepoint, */}
                {/*      userData.patientIds.length */}
                {/*    ).description} */}
                {/* </Media.Body> */}
                <div
                  className="play"
                >
                  <div className="top text-center">
                    <img
                      src={playImg}
                      className="rounded"
                      alt="..."
                      height="47"
                    />
                  </div>
                  <div className="bottom text-center">
                    <span>Play Time</span>
                    <DashboardButton
                      onClick={e =>
                        handleStart(
                          QUESTIONNAIRE_PLAY(
                            userData.timepoint,
                            userData.patientIds.length
                          ),
                          e
                        )
                      }
                    >
                      START
                    </DashboardButton>
                  </div>
                </div>
              </Media>
            </Col>
          </SidebarSection>
        )}
      </div>
      <Modal
        show={openChildSelectModal}
        dialogAs="div"
        onHide={closeSelectChildModal}
      >
        <ChildrenSelect onStart={handleSelectChild} />
      </Modal>
    </>
  )
}

Sidebar.propTypes = {
  openCloseSidebar: PropTypes.func,
  sidebar: PropTypes.bool
}

export default Sidebar
