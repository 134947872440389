import styled from 'styled-components'
import { Card } from 'react-bootstrap'

export const InterventionCardContainer = styled(Card)`
  border: none;
  margin-top: 50px;
`
export const InterventionCardContainerBody = styled(Card.Body)`
  padding-left: 0 !important;
`
export const InterventionCardContainerHeader = styled(Card.Header)`
  background-color: transparent;
  border-bottom: 1px solid #eae8e8;
  padding-bottom: 7px;
`

export const InterventionCard = styled(Card)`
  margin-bottom: 20px;
  border-radius: 15px !important;
  border: 1px solid #eeeeee;
`
export const InterventionCardHeader = styled(Card.Header)`
  background-color: #fff6e4;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border: 1px solid #eeeeee;
`
