/**
 * @typedef {object} Action
 * @property {string} types
 */

export const userActionTypes = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  LOGOUT: 'USERS_LOGOUT',
  LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',
  SESSION_EXTENDED: 'SESSION_EXTENDED',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  USER_SUCCESS: 'USER_SUCCESS',
  USER_FAILURE: 'USER_FAILURE'
}

export const QUESTIONNAIREActionTypes = {
  HIDE: 'HIDE',
  SHOW: 'SHOW',
  QUESTIONNAIRES_LIST_SUCCESS: 'QUESTIONNAIRES_LIST_SUCCESS',
  QUESTIONNAIRES_LIST_FAILED: 'QUESTIONNAIRES_LIST_FAILED'
}

export const PARTICIPANTActionTypes = {
  HIDE_PARTICIPANT_MODAL: 'HIDE_PARTICIPANT_MODAL',
  SHOW_PARTICIPANT_MODAL: 'SHOW_PARTICIPANT_MODAL',
  CREATE_PARTICIPANT: 'CREATE_PARTICIPANT',
  CREATE_SUCCESS: 'PARTICIPANT_CREATE_SUCCESS',
  CREATE_FAILURE: 'PARTICIPANT_CREATE_FAILURE',
  VIEW_SUCCESS: 'PARTICIPANT_VIEW_SUCCESS',
  VIEW_FAILURE: 'PARTICIPANT_VIEW_FAILURE',
  UPDATE_SUCCESS: 'PARTICIPANT_UPDATE_SUCCESS',
  UPDATE_FAILED: 'PARTICIPANT_UPDATE_FAILURE',
  LIST_SUCCESS: 'PARTICIPANT_LIST_SUCCESS',
  LIST_FAILURE: 'PARTICIPANT_LIST_FAILURE',
  SHOW_ASSIGNMENT_MODAL: 'SHOW_ASSIGNMENT_MODAL',
  HIDE_ASSIGNMENT_MODAL: 'HIDE_ASSIGNMENT_MODAL',
  SHOW_PHASE_MODAL: 'SHOW_PHASE_MODAL',
  HIDE_PHASE_MODAL: 'HIDE_PHASE_MODAL',
  INTERVENTION_ASSIGNMENT_SUCCESS: 'INTERVENTION_ASSIGNMENT_SUCCESS',
  MARK_PHONE_CALL_MADE_SUCCESS: 'MARK_PHONE_CALL_MADE_SUCCESS',
  FETCH_INTERVENTION_STATUSES: 'FETCH_INTERVENTION_STATUSES',
  FETCH_QUESTIONNAIRES_STATUSES: 'FETCH_QUESTIONNAIRES_STATUSES',
  UPDATE_PHASE_SUCCESS: 'UPDATE_PHASE_SUCCESS',
  UPDATE_FETCH_LIST: 'UPDATE_FETCH_LIST',
  FETCH_NOTIFICATION_STATUSES: 'FETCH_NOTIFICATION_STATUSES',
}
export const clinicianActionTypes = {
  LIST_SUCCESS: 'CLINICIAN_LIST_SUCCESS',
  LIST_FAILURE: 'CLINICIAN_LIST_FAILURE'
}
