import React from 'react'

const Footer = props => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row" />
      </div>
    </footer>
  )
}

export default Footer
