import axios from 'axios'
import endpoints from 'constants/endpoints'
import { isEmpty } from 'lodash'
import { handleApiException } from './utils'

axios.defaults.withCredentials = true

/**
 * Axios request wrapper
 */
// eslint-disable-next-line import/prefer-default-export
export const api = {
  delete: (url, body = {}) => {
    return axios.delete(url, { data: body }).catch(err => {
      handleApiException(err)
      throw err
    })
  },
  get: (url, params = { withCredentials: true }) => {
    return axios.get(url, params).catch(err => {
      handleApiException(err)
      throw err
    })
  },
  post: (url, body = {}, params = {}) => {
    return axios.post(url, body, params).catch(err => {
      handleApiException(err)
      throw err
    })
  },
  put: (url, body = {}) => {
    return axios.put(url, body).catch(err => {
      handleApiException(err)
      throw err
    })
  },
  patch: (url, body = {}) => {
    return axios.patch(url, body).catch(err => {
      handleApiException(err)
      throw err
    })
  }
}

export function getQuestionnaires() {
  return api.get(endpoints.QUESTIONNAIRES()).then(resp => resp.data)
}
export function getQuestionnairesStatusByPatientID(patientId) {
  return api
    .get(endpoints.QUESTIONNAIRES_STATUS_BY_PATIENTID(), {
      params: { patientIds: patientId }
    })
    .then(resp => resp.data)
}

export function getQuestionnaireByName(name) {
  return api
    .get(endpoints.QUESTIONNAIRE_BY_NAME(name))
    .then(resp => resp.data.parameters)
}

export function submitQuestionnaireResponse(name, body) {
  return api
    .post(endpoints.QUESTIONNAIRE_RESPONSE_BY_NAME(name), body)
    .then(res => res.data)
}

export function createParticipant(body) {
  return api.post(endpoints.CREATE_PARTICIPANT(), body).then(res => res.data)
}

export function viewParticipant(participantId) {
  return api
    .get(endpoints.VIEW_PARTICIPANT(participantId))
    .then(resp => resp.data)
}

export function updateParticipant(participantId, body) {
  return api
    .patch(endpoints.UPDATE_PARTICIPANT(participantId), body)
    .then(res => res.data)
}

export function assignParticipant(group, participantId) {
  return api
    .post(endpoints.ASSIGN_PARTICIPANT(group, participantId))
    .then(resp => resp.data)
}

export function updatePhase(phase, participantId) {
  return api
    .post(endpoints.UPDATE_PHASE(phase, participantId))
    .then(resp => resp.data)
}

export function getParticipants() {
  return api.get(endpoints.PARTICIPANTS()).then(resp => resp.data)
}

export function getParticipantInterventionStatus(participantIds, phase, interventionType) {
  return api
    .get(endpoints.INTERVENTIONS_STATUS(), {
      params: { usernames: participantIds, phase, interventionType }
    })
    .then(resp => resp.data)
}

export function activateIntervention(body) {
  return api
    .post(endpoints.ACTIVATE_INTERVENTION(), body)
    .then(resp => resp.data)
}

export function markPhoneCompleted(notificationScheduleItemId) {
  return api
    .post(endpoints.MARK_PHONE_CALL_COMPLETED(notificationScheduleItemId))
    .then(resp => resp.data)
}

export function getClinicians() {
  return api.get(endpoints.CLINICIANS()).then(resp => resp.data)
}

export function getReportTypes() {
  return api.get(endpoints.REPORT_TYPES()).then(resp => resp.data)
}

export function exportCSV(centerIDs) {
  let endpoint
  const req = {
    method: 'GET',
    responseType: 'blob'
  }
  if (isEmpty(centerIDs)) {
    endpoint = endpoints.EXPORT_All()
  } else {
    endpoint = endpoints.EXPORT_BY_CENTERS()
    req.params = { centerIds: centerIDs.toString() }
  }
  req.url = endpoint

  return axios(req).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'download.csv')
    document.body.appendChild(link)
    link.click()
  })
}

export function getNotificationsStatus(participantIds) {
  return api
    .get(endpoints.NOTIFICATIONS_STATUS(), {
      params: { participantIds }
    })
    .then(resp => resp.data)
}
