/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import { loadingHOC } from 'services/loadingHOC'
import PropTypes from 'prop-types'
import {
  Col,
  Container,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  Row
} from 'react-bootstrap'
import PageSection from 'components/PageSection'
import PageHeader from 'components/PageHeader'
import PageTitle from 'components/PageTitle'
import ButtonStyled from 'components/ButtonStyled'
import { useLocation } from 'react-router'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import ChildContainer from 'components/ChildContainer'
import {
  GROUP_OPTIONS,
  PHASE_OPTIONS,
  ROLE,
  SEX_OPTIONS,
  UNKNOWN_GROUP
} from 'constants/consts';
import Select from 'react-select'
import ErrorMessageStyled from 'components/ErrorMessageStyled'
import { useDispatch, useSelector } from 'react-redux'
import {
  find,
  forOwn,
  includes,
  isEmpty,
  isEqual,
  map,
  split,
  values
} from 'lodash'
import TableTitle from 'components/TableTitle'
import TableContainer from 'components/TableContainer'
import Table from 'components/Table'
import CompleteIcon from 'components/icons/CompleteIcon'
import { IconWrapper16 } from 'components/icons/styles'
import adminActions from 'store/actions/adminActions'
import { getPhaseOptions } from 'services/utils'
import { SentBadge } from 'components/SentBadge'
import { ClearBadge } from 'components/ClearBadge'
import TableButton from 'components/ActivateButton'
import usePrevious from 'services/customHooks'
import fetchQuestionnaires from 'views/dashboard/participant/DashboardView/Tabs'
import { getQuestionnairesStatusByPatientID } from 'services/api'

const ParticipantInfoView = props => {
  const { state } = useLocation()
  const dispatch = useDispatch()
  const userData = useSelector(store => store.user.data)
  const clinicians = useSelector(store => store.clinician.list)
  const [loading, setLoading] = useState({
    participant: false,
    notificationsStatus: false,
    interventionsStatus: false,
    questionnaires: false
  })
  const [participant, setParticipant] = useState({})
  const prevParticipant = usePrevious(participant)
  const [notificationsStatus, setNotificationsStatus] = useState([])
  const prevNotificationsStatus = usePrevious(notificationsStatus)
  const [interventions, setInterventions] = useState([])
  const prevInterventions = usePrevious(interventions)
  const [questionnairesStatus, setQuestionnairesStatus] = useState([])
  const prevQuestionnairesStatus = usePrevious(questionnairesStatus)
  const [clinicianOptions, setClinicianOptions] = useState([])
  const questionnaires = useSelector(
    store => store.questionnaire.questionnaires
  )

  useEffect(() => {
    const loadingClone = { ...loading }
    const { viewStateManager } = props
    if (
      viewStateManager.getState() !== 'READY' &&
      viewStateManager.getState() !== 'LOADING'
    )
      viewStateManager.setLoading()

    if (clinicians && clinicians.length !== 0 && isEmpty(clinicianOptions)) {
      const list = []
      map(clinicians, clinician => {
        list.push({
          value: clinician.username,
          label: `${clinician.forename} ${clinician.surname}`
        })
      })
      setClinicianOptions(list)
    }

    if (!isEqual(participant, prevParticipant))
      dispatch(adminActions.participantView(state.username)).then(data => {
        setParticipant(data)
        loadingClone.participant = false
        setLoading(loadingClone)
      })

    if (participant && !isEqual(notificationsStatus, prevNotificationsStatus))
      dispatch(adminActions.fetchNotificationsStatus(state.username)).then(
        data => {
          setNotificationsStatus(data)
          loadingClone.notificationsStatus = false
          setLoading(loadingClone)
        }
      )

    if (
      !loading.interventionsStatus &&
      participant &&
      !isEqual(interventions, prevInterventions)
    ) {
      loadingClone.interventionsStatus = true
      setLoading(loadingClone)
      dispatch(
        adminActions.fetchInterventionsStatus(state.username, state.phase, null)
      ).then(data => {
        const { interventionMap, interventionStatuses } = data
        map(interventionMap, value => {
          const item = find(interventionStatuses, {
            interventionId: value.interventionId
          })
          Object.assign(value, item)
        })
        const cloned = values(interventionMap)
        setInterventions(cloned)
        loadingClone.interventionsStatus = false
        setLoading(loadingClone)
      })
    }

    if (
      !loading.questionnaires &&
      !isEmpty(participant) &&
      (isEmpty(questionnairesStatus) ||
        !isEqual(questionnairesStatus, prevQuestionnairesStatus))
    ) {
      const questionnairesClone = fetchQuestionnaires(
        participant.timepoint,
        participant.patientIds.length
      )
      const newList = []
      let userProgress = []
      const promises = map(participant.studyChildren, item => {
        return getQuestionnairesStatusByPatientID(
          item.patientId.patientId
        ).then(result => {
          if (!isEmpty(result)) userProgress = userProgress.concat(result)
        })
      })
      Promise.all(promises).then(() => {
        map(questionnairesClone, quest => {
          if (!quest.perChild) {
            const childQuestsProgress = find(userProgress, progress => {
              return (
                progress.patientId.patientId === participant.username &&
                progress.timepoint === participant.timepoint &&
                progress.questionnaireName === quest.name
              )
            })
            const newQuest = {
              ...quest,
              username: map(
                participant.studyChildren,
                'patientId.patientId'
              ).toString(),
              isCompleted: !!(
                childQuestsProgress && childQuestsProgress.status === 'COMPLETE'
              )
            }
            newList.push(newQuest)
          } else {
            map(participant.studyChildren, child => {
              const childQuestsProgress = find(userProgress, progress => {
                return (
                  progress.patientId.patientId === child.patientId.patientId &&
                  progress.timepoint === participant.timepoint &&
                  progress.questionnaireName === quest.name
                )
              })
              const newQuest = {
                ...quest,
                username: child.patientId.patientId,
                isCompleted: !!(
                  childQuestsProgress &&
                  childQuestsProgress.status === 'COMPLETE'
                )
              }
              newList.push(newQuest)
            })
          }
        })
        setQuestionnairesStatus(newList)
      })
    }

    if (Object.values(loading).every(v => v === false)) {
      viewStateManager.setReady()
    }
  }, [clinicians, notificationsStatus, participant, interventions])

  const handleUpdate = formData => {
    const data = {}
    forOwn(formData, (value, key) => {
      if (includes(key, 'child')) {
        data.studyChildren = {}
        let str = split(key, 'child-')
        str = split(str[1], '-deceased')
        data.studyChildren[str[0]] = {
          deceased: value
        }
      } else data[key] = value
    })
    dispatch(adminActions.participantUpdate(participant.username, data))
  }

  const enableIntervention = interventionId => {
    dispatch(
      adminActions.enableIntervention({
        username: state.username,
        interventionId,
        available: true
      })
    ).then(() => {
      dispatch(
        adminActions.fetchInterventionsStatus(state.username, state.phase, null)
      ).then(data => {
        const { interventionMap, interventionStatuses } = data
        map(interventionMap, value => {
          const item = find(interventionStatuses, {
            interventionId: value.interventionId
          })
          Object.assign(value, item)
        })
        const cloned = values(interventionMap)
        setInterventions(cloned)
      })
    })
  }

  const markPhoneCallMade = notificationScheduleItemId => {
    dispatch(
      adminActions.markPhoneCallMade(
        notificationScheduleItemId
      )
    ).then(() => {
      dispatch(
        adminActions.fetchNotificationsStatus(state.username)
      ).then(
        data => {
          setNotificationsStatus(data)
      })
    })
  }

  const questionnairesColumns = React.useMemo(
    () => [
      {
        Header: 'Questionnaire Key',
        accessor: 'name'
      },
      {
        Header: 'Questionnaire Title',
        accessor: 'title'
      },
      {
        Header: 'Participant ID',
        accessor: 'username'
      },
      {
        Header: 'Per Child',
        accessor: 'perChild',
        Cell: cellProps => <span>{!cellProps.value ? 'No' : 'Yes'}</span>
      },
      {
        Header: 'Completed',
        accessor: 'isCompleted',
        Cell: cellProps => (
          <span>
            {cellProps.value && (
              <IconWrapper16>
                <CompleteIcon data-tip data-for="completed" />
              </IconWrapper16>
            )}
          </span>
        )
      }
    ],
    []
  )

  const notificationsColumns = React.useMemo(
    () => [
      {
        Header: 'Questionnaire Title',
        accessor: 'questionnaireId',
        Cell: cellProps => {
          const t = find(questionnaires, {
            questionnaireId: cellProps.value
          })
          return t ? t.questionnaireDisplayName : ''
        }
      },
      {
        Header: 'Time For Completion',
        accessor: 'timeForCompletion'
      },
      {
        Header: 'Lock Out Date',
        accessor: 'lockoutDate'
      },
      {
        Header: 'First Reminder',
        accessor: 'first-reminder',
        Cell: cellProps => (
          <div>
            {cellProps.row.original.firstReminderSent ? (
              <SentBadge>Sent</SentBadge>
            ) : (
              <ClearBadge>Not Sent</ClearBadge>
            )}
          </div>
        )
      },
      {
        Header: 'Second Reminder',
        accessor: 'second-reminder',
        Cell: cellProps => (
          <span>
            {cellProps.row.original.secondReminderSent ? (
              <SentBadge>Sent</SentBadge>
            ) : (
              <ClearBadge>Not Sent</ClearBadge>
            )}
          </span>
        )
      },
      {
        Header: 'Third Reminder',
        accessor: 'third-reminder',
        Cell: cellProps => (
          <span>
            {cellProps.row.original.thirdReminderSent ? (
              <SentBadge>Sent</SentBadge>
            ) : (
              <ClearBadge>Not Sent</ClearBadge>
            )}
          </span>
        )
      },
      {
        Header: 'Phone Call',
        accessor: 'phoneCallCompleted',
        Cell: cellProps => {
          return (
            <div>
              {cellProps.row.original.phoneCallCompleted ? (
                <SentBadge>Called</SentBadge>
              ) : (
                <TableButton
                  disabled={
                    !(
                      cellProps.row.original.firstReminderSent &&
                      cellProps.row.original.secondReminderSent &&
                      cellProps.row.original.thirdReminderSent
                    )
                  }
                  onClick={() =>
                    markPhoneCallMade(cellProps.row.original.notificationScheduleItemId)
                  }
                >
                  Call Made
                </TableButton>
              )}
            </div>
          )
        }
      },
      {
        Header: 'Completed',
        accessor: 'complete',
        Cell: cellProps => (
          <span>
            {cellProps.value && (
              <IconWrapper16>
                <CompleteIcon data-tip data-for="complete" />
              </IconWrapper16>
            )}
          </span>
        )
      }
    ],
    []
  )

  const interventionsColumns = React.useMemo(
    () => [
      {
        Header: 'Intervention Name',
        accessor: 'interventionName'
      },
      {
        Header: 'Intervention Text',
        accessor: 'interventionText'
      },
      {
        Header: 'Phase',
        accessor: 'phase',
        Cell: () => (
          <span>
            {
              find(PHASE_OPTIONS, {
                value: state.phase
              }).label
            }
          </span>
        )
      },
      {
        Header: 'Participant Has Viewed',
        accessor: 'participantHasViewedIntervention',
        Cell: cellProps => (
          <span>
            {cellProps.value && (
              <IconWrapper16>
                <CompleteIcon data-tip data-for="complete" />
              </IconWrapper16>
            )}
          </span>
        )
      },
      {
        Header: 'Available To Participant',
        accessor: 'interventionAvailableToParticipant',
        Cell: cellProps => {
          return (
            <div>
              {cellProps.row.original.interventionAvailableToParticipant ? (
                <IconWrapper16>
                  <CompleteIcon data-tip data-for="complete" />
                </IconWrapper16>
              ) : (
                <TableButton
                  onClick={() =>
                    enableIntervention(cellProps.row.original.interventionId)
                  }
                >
                  Enable
                </TableButton>
              )}
            </div>
          )
        }
      }
    ],
    []
  )

  return (
    <Container fluid className="pl-4">
      <Formik
        initialValues={{}}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(false)
          handleUpdate(data)
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <>
            <PageHeader>
              <Col>
                <PageTitle>Participant Details</PageTitle>
              </Col>
              <Col>
                <ButtonStyled
                  type="submit"
                  className="float-right"
                  onClick={handleSubmit}
                >
                  {isSubmitting ? 'Please wait...' : 'Update Participant'}
                </ButtonStyled>
              </Col>
            </PageHeader>
            <Form>
              <PageSection>
                <Col>
                  <FormGroup as={Row}>
                    <FormLabel column sm="3">
                      Participant ID:
                    </FormLabel>
                    <Col sm="9">
                      <FormControl
                        readOnly
                        defaultValue={participant.username}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <FormLabel column sm="3">
                      Study Timepoint:
                    </FormLabel>
                    <Col sm="9">
                      <FormControl
                        readOnly
                        defaultValue={participant.timepoint}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <FormLabel column sm="3">
                      Entry Date:
                    </FormLabel>
                    <Col sm="9">
                      <FormControl
                        readOnly
                        defaultValue={participant.entryDate}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <FormLabel column sm="3">
                      Study Group:
                    </FormLabel>
                    <Col sm="9">
                      <FormControl
                        readOnly
                        value={
                          participant.studyGroup
                            ? find(GROUP_OPTIONS, {
                                value: participant.studyGroup
                              }).key
                            : UNKNOWN_GROUP
                        }
                      />
                    </Col>
                  </FormGroup>

                  <Field name="phase">
                    {({ field, form }) => (
                      <>
                        <FormGroup as={Row} className="mt-4">
                          <FormLabel column sm="3">
                            Phase:
                          </FormLabel>
                          <Col sm="9">
                            {!isEmpty(participant) && (
                              <>
                                <Select
                                  defaultValue={{
                                    label: find(PHASE_OPTIONS, {
                                      value: participant.phase
                                    }).label,
                                    value: participant.phase
                                  }}
                                  name={field.name}
                                  options={getPhaseOptions(participant.phase)}
                                  isSearchable
                                  isClearable
                                  onChange={option =>
                                    form.setFieldValue(field.name, option.value)
                                  }
                                />
                                <ErrorMessage name="phase">
                                  {msg => (
                                    <ErrorMessageStyled>
                                      {msg}
                                    </ErrorMessageStyled>
                                  )}
                                </ErrorMessage>
                              </>
                            )}
                          </Col>
                        </FormGroup>
                      </>
                    )}
                  </Field>

                  {userData && userData.role === ROLE.ADMIN && (
                    <Field name="primaryDoctorUsername">
                      {({ field, form }) => (
                        <>
                          <FormGroup as={Row} className="mt-4">
                            <FormLabel column sm="3">
                              Primary Clinician:
                            </FormLabel>
                            <Col sm="9">
                              {!isEmpty(participant) && (
                                <>
                                  <Select
                                    defaultValue={{
                                      label: `${participant.primaryDoctor.forename} ${participant.primaryDoctor.surname}`,
                                      value: participant.primaryDoctor.username
                                    }}
                                    name={field.name}
                                    options={clinicianOptions}
                                    isSearchable
                                    isClearable
                                    onChange={option =>
                                      form.setFieldValue(
                                        field.name,
                                        option.value
                                      )
                                    }
                                  />
                                  <ErrorMessage name="primaryDoctorUsername">
                                    {msg => (
                                      <ErrorMessageStyled>
                                        {msg}
                                      </ErrorMessageStyled>
                                    )}
                                  </ErrorMessage>
                                </>
                              )}
                            </Col>
                          </FormGroup>
                        </>
                      )}
                    </Field>
                  )}

                  <Field name="secondaryDoctorUsernames">
                    {({ field, form }) => (
                      <>
                        <FormGroup as={Row} className="mt-4">
                          <FormLabel column sm="3">
                            Secondary Clinicians:
                          </FormLabel>
                          <Col lsm="9">
                            {!isEmpty(participant) && (
                              <>
                                <Select
                                  name={field.name}
                                  options={clinicianOptions}
                                  defaultValue={map(
                                    participant.secondaryDoctors,
                                    doctor => {
                                      return {
                                        label: `${doctor.forename} ${doctor.surname}`,
                                        value: doctor.username
                                      }
                                    }
                                  )}
                                  isSearchable
                                  isClearable
                                  isMulti
                                  onChange={options =>
                                    form.setFieldValue(
                                      field.name,
                                      map(options, option => option.value)
                                    )
                                  }
                                />
                                <ErrorMessage name="secondaryDoctorUsernames">
                                  {msg => (
                                    <ErrorMessageStyled>
                                      {msg}
                                    </ErrorMessageStyled>
                                  )}
                                </ErrorMessage>
                              </>
                            )}
                          </Col>
                        </FormGroup>
                      </>
                    )}
                  </Field>

                  {!isEmpty(participant) &&
                    participant.studyChildren.map(child => {
                      return (
                        <>
                          <Field
                            name={`child-${child.patientId.patientId}-deceased`}
                          >
                            {({ field, form }) => {
                              return (
                                <FormGroup as={Row}>
                                  <FormLabel column sm="3">
                                    Child {child.patientId.patientId}:
                                  </FormLabel>
                                  <Col sm="9" className="mt-2">
                                    <ChildContainer>
                                      <FormControl
                                        className="mb-2"
                                        readOnly
                                        defaultValue={
                                          find(SEX_OPTIONS, {
                                            value: child.sex
                                          }).key
                                        }
                                      />
                                      <FormCheck
                                        type="checkbox"
                                        label="Deceased"
                                        defaultChecked={child.deceased}
                                        onChange={option =>
                                          form.setFieldValue(
                                            field.name,
                                            option.target.checked
                                          )
                                        }
                                      />
                                    </ChildContainer>
                                  </Col>
                                </FormGroup>
                              )
                            }}
                          </Field>
                        </>
                      )
                    })}

                  <Field name="note">
                    {({ field }) => (
                      <FormGroup as={Row}>
                        <FormLabel column sm="3">
                          Note:
                        </FormLabel>
                        <Col sm="9">
                          <FormControl
                            {...field}
                            as="textarea"
                            rows={3}
                            defaultValue={participant.note}
                          />
                        </Col>
                      </FormGroup>
                    )}
                  </Field>
                </Col>
              </PageSection>
            </Form>

            <PageSection>
              <Col>
                <TableTitle className="mb-4 mt-4">
                  Participant and children progress on completing questionnaires
                  for {participant.timepoint} timepoint{' '}
                </TableTitle>
                <TableContainer>
                  {questionnairesStatus && (
                    <Table
                      columns={questionnairesColumns}
                      data={questionnairesStatus}
                    />
                  )}
                </TableContainer>
              </Col>
            </PageSection>

            <PageSection>
              <Col>
                <TableTitle className="mb-4 mt-4">
                  Notification Status for Questionnaires
                </TableTitle>
                <TableContainer>
                  {notificationsStatus && (
                    <Table
                      columns={notificationsColumns}
                      data={notificationsStatus}
                    />
                  )}
                </TableContainer>
              </Col>
            </PageSection>

            <PageSection>
              <Col>
                <TableTitle className="mb-4 mt-4">
                  Interventions in{' '}
                  {
                    find(PHASE_OPTIONS, {
                      value: state.phase
                    }).label
                  }
                </TableTitle>
                <TableContainer>
                  {questionnairesStatus && (
                    <Table
                      columns={interventionsColumns}
                      data={interventions}
                    />
                  )}
                </TableContainer>
              </Col>
            </PageSection>
          </>
        )}
      </Formik>
    </Container>
  )
}

ParticipantInfoView.propTypes = {
  viewStateManager: PropTypes.shape({
    setLoading: PropTypes.func,
    setReadyDelayed: PropTypes.func,
    getState: PropTypes.func,
    setReady: PropTypes.func
  })
}
export default loadingHOC(ParticipantInfoView)
