import React from 'react'
import { useSelector } from 'react-redux'

const UserPanel = () => {
  const userData = useSelector(state => state.user.data)

  return (
    <>
      <div className="sidebar-user">
        <h6 className="mt-1 f-14">Parent ID: {userData.username}</h6>
      </div>
    </>
  )
}

export default UserPanel
