/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import { loadingHOC } from 'services/loadingHOC'
import PropTypes from 'prop-types'
import { Col, Container, OverlayTrigger } from 'react-bootstrap'
import PageSection from 'components/PageSection'
import PageHeader from 'components/PageHeader'
import PageTitle from 'components/PageTitle'
import { useDispatch, useSelector } from 'react-redux'
import ButtonStyled from 'components/ButtonStyled'
import TableTitle from 'components/TableTitle'
import TableContainer from 'components/TableContainer'
import TableSearch from 'components/TableSearch'
import Table from 'components/Table'
import { matchSorter } from 'match-sorter'
import adminActions from 'store/actions/adminActions'
import {
  assign,
  find,
  includes,
  isEmpty,
  map,
  startCase,
  toLower
} from 'lodash'
import ButtonLink from 'components/ButtonLink'
import TableButton from 'components/ActivateButton'
import AssignmentPopover from 'components/forms/AssignmentPopover'
import clinicianActions from 'store/actions/clinicianActions'
import { PHASE_OPTIONS, ROUTES } from 'constants/consts'
import { useNavigate } from 'react-router'
import PhasePopover from 'components/forms/PhasePopover'
import { exportCSV, getReportTypes } from 'services/api'
import questionnaireActions from 'store/actions/questionnaireActions'

const Dashboard = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ifFetchList = useSelector(state => state.admin.ifFetchList)
  const [originalData, setOriginalData] = useState(undefined)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const userData = useSelector(store => store.user.data)
  const clinicians = useSelector(store => store.clinician.list)
  const participants = useSelector(store => store.admin.participants)
  const notificationStatuses = useSelector(
    store => store.admin.notificationStatuses
  )

  useEffect(() => {
    const { viewStateManager } = props
    if (
      viewStateManager.getState() !== 'READY' &&
      viewStateManager.getState() !== 'LOADING'
    )
      viewStateManager.setLoading()
    if (originalData === undefined || ifFetchList) {
      Promise.all([
        dispatch(adminActions.participantList()),
        dispatch(clinicianActions.list()),
        dispatch(questionnaireActions.fetchQuestionnaires())
      ]).then(response => {
        const usernames = map(response[0], 'username').toString()
        Promise.all([
          dispatch(adminActions.fetchNotificationsStatus(usernames))
        ]).then(() => {
          if (!isEmpty(notificationStatuses)) {
            map(participants, user => {
              const item = find(notificationStatuses, {
                username: user.username
              })
              if (item) assign(user, item)
            })
          }
          setData(response[0])
          setOriginalData(response[0])
          viewStateManager.setReady()
        })
      })
    }
  }, [search, originalData, ifFetchList])

  const handleSearch = e => {
    const searchValue = e.target.value
    const filteredData = matchSorter(originalData, searchValue, {
      keys: ['username', 'timepoint', 'studyGroup', 'childrenIds', 'childNum']
    })
    setSearch(searchValue)
    setData(filteredData)
  }

  const goToParticipantInfo = participant => {
    navigate(ROUTES.PARTICIPANT_INFO, { state: participant })
  }

  const openParticipantModal = () => {
    dispatch(adminActions.showParticipantModal())
  }
  const exportA = () => {
    getReportTypes().then(types => {
      if (includes(types, 'FULL_PARTICIPANT_ALL_CENTERS_EXPORT')) {
        exportCSV().then()
      } else {
        const centerId = find(clinicians, { username: userData.username })
          .center
        exportCSV([centerId]).then()
      }
    })
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Parent ID',
        accessor: 'username',
        Cell: cellProps => (
          <ButtonLink
            variant="link"
            onClick={() => goToParticipantInfo(cellProps.row.original)}
          >
            {cellProps.value}
          </ButtonLink>
        )
      },
      {
        Header: 'Timepoint',
        accessor: 'timepoint'
      },
      {
        Header: 'Child ID',
        accessor: 'childrenIds',
        Cell: cellProps => (
          <span>
            {map(cellProps.row.original.patientIds, 'patientId').toString()}
          </span>
        )
      },
      {
        Header: 'Children Num',
        accessor: 'childNum',
        Cell: cellProps => cellProps.row.original.patientIds.length
      },
      {
        Header: 'Phase',
        accessor: 'phase',
        Cell: cellProps => {
          if (cellProps.row.original.phase !== 'PHASE_1')
            return (
              <span>{startCase(toLower(cellProps.row.original.phase))}</span>
            )
          return (
            <OverlayTrigger
              trigger="click"
              placement="left"
              overlay={
                <PhasePopover
                  cell={cellProps}
                  value={cellProps.row.original.phase}
                />
              }
              {...cellProps}
              rootClose
            >
              <TableButton>
                {
                  find(PHASE_OPTIONS, {
                    value: cellProps.row.original.phase
                  }).label
                }
              </TableButton>
            </OverlayTrigger>
          )
        }
      },
      {
        Header: 'Group Assignment',
        accessor: 'studyGroup',
        Cell: cellProps => {
          if (cellProps.row.original.studyGroup !== 'UNASSIGNED')
            return (
              <span>
                {startCase(toLower(cellProps.row.original.studyGroup))}
              </span>
            )
          return (
            <OverlayTrigger
              trigger="click"
              placement="left"
              overlay={<AssignmentPopover cell={cellProps} />}
              {...cellProps}
              rootClose
            >
              <TableButton>Assign</TableButton>
            </OverlayTrigger>
          )
        }
      }
    ],
    []
  )

  return (
    <Container fluid className="pl-4">
      <PageHeader>
        <Col>
          <PageTitle>ADMIN DASHBOARD</PageTitle>
        </Col>
        <Col>
          <ButtonStyled className="float-right" onClick={openParticipantModal}>
            Create Participant
          </ButtonStyled>
          <ButtonStyled className="float-right mr-1" onClick={exportA}>
            Export
          </ButtonStyled>
        </Col>
      </PageHeader>
      <PageSection>
        <Col>
          <TableTitle>Participant List</TableTitle>
          <TableContainer>
            <TableSearch
              placeholder="Search"
              value={search}
              onChange={e => handleSearch(e)}
            />
            {data && <Table columns={columns} data={data} />}
          </TableContainer>
        </Col>
      </PageSection>
    </Container>
  )
}

Dashboard.propTypes = {
  viewStateManager: PropTypes.shape({
    setLoading: PropTypes.func,
    getState: PropTypes.func,
    setReady: PropTypes.func,
    setReadyDelayed: PropTypes.func
  })
}
export default loadingHOC(Dashboard)
