import store from 'store'
import * as toasts from 'services/toasts'
import { PARTICIPANT_MSG } from 'constants/messages'
import {
  activateIntervention,
  assignParticipant,
  createParticipant,
  getNotificationsStatus,
  getParticipantInterventionStatus,
  getParticipants,
  getQuestionnairesStatusByPatientID, markPhoneCompleted,
  updateParticipant,
  updatePhase,
  viewParticipant
} from 'services/api'
import { PARTICIPANTActionTypes } from './actionTypes'

const setFetchList = bool => dispatch => {
  dispatch({
    type: PARTICIPANTActionTypes.UPDATE_FETCH_LIST,
    bool
  })
}
const showParticipantModal = () => dispatch => {
  dispatch({
    type: PARTICIPANTActionTypes.SHOW_PARTICIPANT_MODAL
  })
}

const hideParticipantModal = () => dispatch => {
  dispatch({
    type: PARTICIPANTActionTypes.HIDE_PARTICIPANT_MODAL
  })
}

const participantList = () => {
  const listSuccess = data => {
    return {
      type: PARTICIPANTActionTypes.LIST_SUCCESS,
      data
    }
  }
  const listFailure = listError => {
    return {
      type: PARTICIPANTActionTypes.LIST_FAILURE,
      listError
    }
  }
  return async dispatch => {
    return getParticipants()
      .then(response => {
        store.set('participants', response)
        dispatch(listSuccess(response))
        return response
      })
      .catch(error => {
        store.set('participants', [])
        dispatch(listFailure(error.message))
        toasts.error(PARTICIPANT_MSG.LIST_FAILED)
      })
  }
}

const participantCreate = participant => {
  const createSuccess = () => {
    return {
      type: PARTICIPANTActionTypes.CREATE_SUCCESS
    }
  }
  return async dispatch => {
    return createParticipant(participant)
      .then(response => {
        dispatch(createSuccess())
        toasts.success(PARTICIPANT_MSG.CREATE_SUCCESS, 3000)
        return response
      })
      .catch(() => {
        toasts.error(PARTICIPANT_MSG.CREATE_FAILED, 3000)
      })
  }
}

const participantView = participantId => {
  const viewSuccess = () => {
    return {
      type: PARTICIPANTActionTypes.VIEW_SUCCESS
    }
  }
  return async dispatch => {
    return viewParticipant(participantId)
      .then(response => {
        dispatch(viewSuccess(response))
        return response
      })
      .catch(() => {
        toasts.error(PARTICIPANT_MSG.VIEW_FAILED, 3000)
      })
  }
}

const participantUpdate = (participantId, data) => {
  const updateSuccess = () => {
    return {
      type: PARTICIPANTActionTypes.UPDATE_SUCCESS
    }
  }
  return async dispatch => {
    return updateParticipant(participantId, data)
      .then(response => {
        dispatch(updateSuccess())
        toasts.success(PARTICIPANT_MSG.UPDATE_SUCCESS, 3000)
        return response
      })
      .catch(() => {
        toasts.error(PARTICIPANT_MSG.UPDATE_FAILED, 3000)
      })
  }
}

const assign = (group, participantId) => {
  const assignSuccess = () => {
    return {
      type: PARTICIPANTActionTypes.INTERVENTION_ASSIGNMENT_SUCCESS
    }
  }
  return async dispatch => {
    return assignParticipant(group, participantId)
      .then(() => {
        dispatch(assignSuccess())
        toasts.success(PARTICIPANT_MSG.ASSIGN_SUCCESS, 3000)
      })
      .catch(error => {
        toasts.error(error)
      })
  }
}

const phaseUpdate = (phase, participantId) => {
  const updatePhaseSuccess = () => {
    return {
      type: PARTICIPANTActionTypes.UPDATE_PHASE_SUCCESS
    }
  }
  return async dispatch => {
    return updatePhase(phase, participantId)
      .then(() => {
        dispatch(updatePhaseSuccess())
        toasts.success(PARTICIPANT_MSG.UPDATE_PHASE_SUCCESS, 3000)
      })
      .catch(error => {
        toasts.error(error.response.data.message)
      })
  }
}

const enableIntervention = body => {
  const enableSuccess = () => {
    return {
      type: PARTICIPANTActionTypes.INTERVENTION_ASSIGNMENT_SUCCESS
    }
  }
  return async dispatch => {
    return activateIntervention(body)
      .then(() => {
        dispatch(enableSuccess())
        if (body.available)
          toasts.success(PARTICIPANT_MSG.INTERVENTION_ASSIGNMENT_SUCCESS, 3000)
        else toasts.success(PARTICIPANT_MSG.DISABLE_INTERVENTION_SUCCESS, 3000)
      })
      .catch(error => {
        toasts.error(error.response.data.message)
      })
  }
}

const markPhoneCallMade = body => {
  const enableSuccess = () => {
    return {
      type: PARTICIPANTActionTypes.MARK_PHONE_CALL_MADE_SUCCESS
    }
  }
  return async dispatch => {
    return markPhoneCompleted(body)
      .then(() => {
        dispatch(enableSuccess())
        toasts.success(PARTICIPANT_MSG.MARK_PHONE_CALL_MADE_SUCCESS, 3000)
      })
      .catch(error => {
        toasts.error(error.response.data.message)
      })
  }
}

const fetchInterventionsStatus = (participantIds, phase, interventionType) => {
  const fetchSuccess = statuses => {
    return {
      type: PARTICIPANTActionTypes.FETCH_INTERVENTION_STATUSES,
      statuses,
      interventionType
    }
  }
  return async dispatch => {
    return getParticipantInterventionStatus(participantIds, phase, interventionType)
      .then(statuses => {
        dispatch(fetchSuccess(statuses))
        return statuses
      })
      .catch(error => {
        toasts.error(error.response.data.message)
      })
  }
}

const fetchQuestionnairesStatus = participantIds => {
  const fetchSuccess = statuses => {
    return {
      type: PARTICIPANTActionTypes.FETCH_QUESTIONNAIRES_STATUSES,
      statuses
    }
  }
  return async dispatch => {
    return getQuestionnairesStatusByPatientID(participantIds)
      .then(statuses => {
        dispatch(fetchSuccess(statuses))
        return statuses
      })
      .catch(error => {
        toasts.error(error.response.data.message)
      })
  }
}
const fetchNotificationsStatus = participantIds => {
  const fetchSuccess = statuses => {
    return {
      type: PARTICIPANTActionTypes.FETCH_NOTIFICATION_STATUSES,
      statuses
    }
  }
  return async dispatch => {
    return getNotificationsStatus(participantIds)
      .then(statuses => {
        dispatch(fetchSuccess(statuses))
        return statuses
      })
      .catch(error => {
        toasts.error(error.response.data.message)
      })
  }
}

export default {
  showParticipantModal,
  hideParticipantModal,
  participantCreate,
  participantList,
  participantView,
  participantUpdate,
  assign,
  phaseUpdate,
  enableIntervention,
  fetchInterventionsStatus,
  fetchQuestionnairesStatus,
  fetchNotificationsStatus,
  setFetchList,
  markPhoneCallMade
}
