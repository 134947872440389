import styled from 'styled-components'
import { FormCheck, Popover } from 'react-bootstrap'

import ButtonStyled from 'components/ButtonStyled'

const PopoverFormCheckStyled = styled(FormCheck)``
const PopoverButtonStyled = styled(ButtonStyled)``

const PopoverStyled = styled(Popover)`
  & .popover-body {
    padding: 0 0px 5px 0;
  }

  ${PopoverButtonStyled} {
    font-size: 0.7rem;
    padding: 3px 13px;
    margin-left: 10px;
  }
`

const PopoverHeaderStyled = styled(Popover.Title)`
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
`

export {
  PopoverStyled,
  PopoverFormCheckStyled,
  PopoverButtonStyled,
  PopoverHeaderStyled
}
