import { clinicianActionTypes } from 'store/actions/actionTypes'
import store from 'store'

const initialState = {
  list: store.get('clinicians')
}

const clinician = (state = initialState, action) => {
  switch (action.type) {
    case clinicianActionTypes.LIST_SUCCESS:
      return {
        list: action.data
      }

    case clinicianActionTypes.LIST_FAILURE:
      return {
        list: []
      }

    default:
      return state
  }
}
export default clinician
