import styled from 'styled-components'
import { Button } from 'react-bootstrap'

const TableButton = styled(Button)`
  background-color: #e0937f;
  border-color: #e0937f;
  padding: 1px 5px;
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: #ffffff;

  :hover,
  :focus {
    background-color: #b28364;
    border-color: #b28364;
  }
  :disabled {
    background-color: #e1e1e1;
    border-color: #e1e1e1;
  }
`
export default TableButton
