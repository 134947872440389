import styled from 'styled-components'
import { Card, Col } from 'react-bootstrap'
import { PHASE_STATUS } from 'constants/consts'

export const PhaseCol = styled(Col)`
  max-width: 210px;
`
export const PhaseCard = styled(Card)`
  max-width: 290px;
  margin-bottom: 20px;
  border-radius: 15px !important;
  border: ${props =>
    props.disabled ? '1px solid #f0f0f0' : '1px solid #f9edd7'};

  img {
    max-width: 190px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    filter: ${props =>
      props.status !== PHASE_STATUS.ENABLED ? 'grayscale(100%)' : 'none'};
    opacity: ${props =>
      props.status !== PHASE_STATUS.ENABLED ? '63.9%' : '100%'};
  }

  :hover {
    transform: ${props => (props.disabled ? 'none' : 'scale(1.07)')};
    transition: transform 0.75s, visibility 0.25s ease-in;
  }
`
export const PhaseCardSmaller = styled(Card)`
  max-width: none;
  margin-bottom: 0;
  border: 1px solid #f9edd7;
  border-radius: 15px !important;
  background-color: #f9edd7;
  img {
    min-height: 0;
    max-height: 113px;
    max-width: none;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }
  :hover {
    transform: scale(1.07);
    transition: transform 0.75s, visibility 0.25s ease-in;
  }
`
export const PhaseCardSmallerBody = styled(Card.Body)`
  padding: 9px !important;
`

export const PhaseCardBody = styled(Card.Body)`
  padding: 1rem 0 1rem 0rem !important;
`
export const PhaseLink = styled(Card.Link)`
  pointer-events: ${props =>
    props.status === PHASE_STATUS.NO_ACCESS ? 'none' : 'auto'};
  cursor: ${props =>
    props.status === PHASE_STATUS.NO_ACCESS
      ? 'not-allowed !important'
      : 'pointer'};
`
