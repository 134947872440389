import styled from 'styled-components'

export const IconWrapper28 = styled.div`
  height: 32px;
  width: 32px;
`
export const IconWrapper24 = styled.div`
  height: auto;
  width: 24px;
  margin-right: 5px;

  @media (max-width: 480px) {
    margin-right: 0px;
  }
`

export const IconWrapper16 = styled.div`
  height: auto;
  width: 16px;
`
export const IconWrapper12 = styled.div`
  height: auto;
  width: 12px;
`
