import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from 'layouts/DashboardLayout/header'
import Sidebar from 'layouts/DashboardLayout/sidebar'
import Footer from 'layouts/DashboardLayout/footer'
import { ToastContainer } from 'react-toastify'
import { withCookies } from 'react-cookie'
import { useSelector } from 'react-redux'

const DashboardLayout = () => {
  const [sidebar, setSidebar] = useState(false)
  const clinicians = useSelector(store => store.clinician.list)

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(!sidebar)
      document.querySelector('.page-main-header').classList.remove('open')
      document.querySelector('.page-sidebar').classList.remove('open')
      document.querySelector('.page-body').classList.remove('open')
    } else {
      setSidebar(!sidebar)
      document.querySelector('.page-main-header').classList.add('open')
      document.querySelector('.page-sidebar').classList.add('open')
      document.querySelector('.page-body').classList.add('open')
    }
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <Header
            clinicians={clinicians}
            openCloseSidebar={openCloseSidebar}
            sidebar={sidebar}
          />
          <Sidebar sidebar={sidebar} openCloseSidebar={openCloseSidebar} />
          <div className="page-body">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default withCookies(DashboardLayout)
