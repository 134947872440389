import 'react-perfect-scrollbar/dist/css/styles.css'
import React from 'react'
import { useRoutes } from 'react-router-dom'
import routes from 'routes/routes'

const App = () => {
  const routing = useRoutes(routes)
  return <>{routing}</>
}

export default App
