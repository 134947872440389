/* eslint-disable */
import { API_MSG } from 'constants/messages';
import { filter, has, map, toPairs, zipObject } from 'lodash';
import moment from 'moment';
import * as message from 'services/toasts';
import store from 'store';
import * as toast from './toasts';
import { GROUP_OPTIONS, PHASE_OPTIONS, ROUTES } from 'constants/consts';

/**
 * Return the class name (or not) based on the condition.
 * If true, return the class name with one leading space.
 * Else return empty string.
 * Designed for appending conditional class names in string templates.
 * @param {boolean} condition
 * @param {string} className
 * @returns {string} - className or empty string
 */
export function addClassIf(condition, className) {
  return condition ? ` ${className}` : ''
}

/**
 * Create an object with default value on undefined props.
 * @param {*} defaultValue - Default value for undefined props.
 * @return {object}
 */
export function defaultObject(defaultValue) {
  return new Proxy(
    {},
    {
      get(object, property) {
        return has(object, property) ? object[property] : defaultValue
      }
    }
  )
}

/**
 * Filter out the array with the date specified
 * @param {array} array The array of object in which has a key named "date"
 * @param {string} date The date string
 * @return {array}
 */
export function filterArrayByDate(array, date) {
  return array.filter(item => {
    return moment(item.date).format('YYYY-MM-DD') === date
  })
}

/**
 * Handle axios request exception. It includes exception http code 500, 404, 401, 403 etc.
 * @param dispatch The dispatch function from store
 * @param error The error object from Nodejs, {response: {object}, stacktrack: {object}}
 */
export function handleApiException(error) {
  const { status } = error.response
  const errorMessage =
    error.response.data.message || error.response.data.errorMessage || undefined
  if (error.response.status === 401) {
    store.set('isAuthenticated', false)
    window.location.href = ROUTES.LOGIN
  } else if (status === 400) {
    message.error(errorMessage || API_MSG.INVALID_OPERATION)
  } else if (status === 401) {
    message.error(errorMessage || API_MSG.UNAUTHENTICATED)
  } else if (status === 403) {
    message.error(errorMessage || API_MSG.UNAUTHORIZED)
  } else if (status === 404) {
    message.error(errorMessage || API_MSG.DATA_NOT_AVAILABLE)
  } else if (status === 500) {
    message.error(errorMessage || API_MSG.SERVER_INTERNAL_ERROR)
  } else if (status === 409) {
    message.error(errorMessage || API_MSG.RESOURCE_CONFLICT)
  } else {
    message.error(errorMessage || API_MSG.UNKNOWN_ERROR)
  }
}

/**
 * Shortcut for resolving all promises with keys in an object. Designed for setState()!
 *
 * @param {object} promises - The promises object to resolve
 * @return {Promise} - Resolved results with keys
 */
export function resolveAsObject(promises) {
  return new Promise((resolve, reject) => {
    const pairs = toPairs(promises)
    const keys = map(pairs, 0)
    Promise.all(map(pairs, 1))
      .then(results => resolve(zipObject(keys, results)))
      .catch(reject)
  })
}

// eslint-disable-next-line consistent-return
export function responseHandler(resp, successMsg) {
  if (resp.status === 200 || resp.status === 201) {
    return Promise.resolve(toast.success(successMsg))
  }
  if (resp.status > 300) {
    return Promise.reject(toast.error(resp.data.message))
  }
}

/**
 * Format date from Australian style to ISO style.
 * DD-MM-YYYY => YYYY-DD-MM
 * @param {string} date - Australian style date (backend style)
 * @returns {string} - ISO style date
 */
export function toIsoDate(date) {
  return moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
}

/**
 * Format date from ISO style to Australian style.
 * YYYY-DD-MM => DD-MM-YYYY
 * @param {string} date - ISO style date
 * @returns {string} - Australian style date (backend style)
 */
export function toAussieDate(date) {
  return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY')
}

/**
 * Format date to readable date
 * @param {string} date
 * @return {string}
 */
export function toDisplayDate(date) {
  return moment(date, 'YYYY-MM-DD').format('DD MMM YYYY')
}

/**
 * Format the value to a 24-hour time format
 * @param {Array} value
 * @returns {Array}
 */
export function timeMask24H(value) {
  const time = value.split('')
  const hours = [/[0-2]/, time[0] === '2' ? /[0-3]/ : /[0-9]/]
  const minutes = [/[0-5]/, /[0-9]/]
  return [...hours, ':', ...minutes]
}
/**
 * Get the list of phase options based on the current phase
 * @param {Object} participant
 * @returns {Array}
 */
export const getPhaseOptions = phase => {
  if (phase === 'PHASE_1') {
    return filter(PHASE_OPTIONS, option => {
      return option.value === 'PHASE_1' || option.value === 'PHASE_2'
    })
  }
  if (phase === 'PHASE_2') {
    return filter(PHASE_OPTIONS, option => {
      return option.value === 'PHASE_2'
    })
  }
  if (phase === 'PHASE_3') {
    return filter(PHASE_OPTIONS, option => {
      return option.value === 'PHASE_3'
    })
  }
  return GROUP_OPTIONS
}
