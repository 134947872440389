import styled from 'styled-components'
import { Form } from 'react-bootstrap'

const CONTAINER = styled(Form)`
  padding-top: 1.4em;
  padding-bottom: 0.3em;

  label {
    margin-left: 4px;
  }
`

const HELP = styled.p`
  color: #e0937f;
  font-size: 0.8em;
  margin: 5px;
`

export { CONTAINER, HELP }
