import * as toasts from 'services/toasts'
import { CLINICIAN_MSG } from 'constants/messages'
import { getClinicians } from 'services/api'
import store from 'store'
import { clinicianActionTypes } from './actionTypes'

const list = () => {
  const listSuccess = data => {
    return {
      type: clinicianActionTypes.LIST_SUCCESS,
      data
    }
  }
  const listFailure = () => {
    return {
      type: clinicianActionTypes.LIST_FAILURE
    }
  }
  return async dispatch => {
    return getClinicians()
      .then(response => {
        dispatch(listSuccess(response))
        store.set('clinicians', response)
      })
      .catch(error => {
        dispatch(listFailure(error.message))
        store.set('clinicians', [])
        toasts.error(CLINICIAN_MSG.LIST_FAILED)
      })
  }
}

export default {
  list
}
