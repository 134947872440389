import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, ModalDialog, ModalTitle } from 'react-bootstrap'
import { CONTAINER, HELP } from 'views/questionnaire/styles'
import * as Yup from 'yup'

import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useSelector } from 'react-redux'
import ButtonStyled from 'components/ButtonStyled'
import ModalHeaderStyled from 'components/ModalHeader'
import ModalFooterStyled from 'components/ModalFooter'
import ModalBodyStyled from 'components/ModalBodyStyled'

const ChildrenSelect = props => {
  const userData = useSelector(state => state.user.data)
  const childrenSchema = Yup.object().shape({
    child: Yup.string()
      .ensure()
      .required('Child is required!')
  })

  return (
    <Formik
      initialValues={{ child: '' }}
      validationSchema={childrenSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false)
        props.onStart(values)
      }}
    >
      {({ touched, errors, isSubmitting }) => (
        <Form>
          <ModalDialog scrollable size="md">
            <ModalHeaderStyled closeButton>
              <ModalTitle>Select Child</ModalTitle>
            </ModalHeaderStyled>
            <ModalBodyStyled>
              <CONTAINER>
                <FormGroup>
                  <label htmlFor="child">
                    Please select the child you are completing the questionnaire for{' '}
                  </label>
                  <Field
                    name="child"
                    as="select"
                    className={`form-control${
                      errors.child && touched.child ? ' is-invalid' : ''
                    }`}
                  >
                    <option value="" />
                    {userData.patientIds.map(item => (
                      <option value={item.patientId}>{item.patientId}</option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="child"
                    component="div"
                    className="invalid-feedback"
                  />
                </FormGroup>
                <HELP>
                  If you're unsure of your children's participant numbers,
                  please refer back to the study welcome email we sent you or
                  email us at tedi-prem@unimelb.edu.au
                </HELP>
              </CONTAINER>
            </ModalBodyStyled>
            <ModalFooterStyled>
              <ButtonStyled type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Please wait...' : 'BEGIN'}
              </ButtonStyled>
            </ModalFooterStyled>
          </ModalDialog>
        </Form>
      )}
    </Formik>
  )
}

ChildrenSelect.propTypes = {
  onStart: PropTypes.func
}
export default ChildrenSelect
