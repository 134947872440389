import { Home } from 'react-feather'
import { ROUTES, TIMEPOINT, TIMEPOINT_KEYS } from 'constants/consts'

export const ADMIN_MENUITEMS = [
  {
    title: 'Participants',
    icon: Home,
    path: ROUTES.ADMIN_DASHBOARD,
    type: 'link',
    active: false
  }
]

export const PARTICIPANT_MENUITEMS = [
  {
    title: TIMEPOINT(TIMEPOINT_KEYS.BASELINE).name,
    icon: Home,
    path: ROUTES.PARTICIPANT_DASHBOARD,
    type: 'link',
    active: false,
    disabled: true
  },
  {
    title: TIMEPOINT(TIMEPOINT_KEYS.MONTH_3).name,
    icon: Home,
    path: ROUTES.PARTICIPANT_DASHBOARD,
    type: 'link',
    active: false,
    disabled: true
  },
  {
    title: TIMEPOINT(TIMEPOINT_KEYS.MONTH_6).name,
    icon: Home,
    path: ROUTES.PARTICIPANT_DASHBOARD,
    type: 'link',
    active: false,
    disabled: true
  },
  {
    title: TIMEPOINT(TIMEPOINT_KEYS.MONTH_12).name,
    icon: Home,
    path: ROUTES.PARTICIPANT_DASHBOARD,
    type: 'link',
    active: false,
    disabled: true
  },
  {
    title: TIMEPOINT(TIMEPOINT_KEYS.MONTH_18).name,
    icon: Home,
    path: ROUTES.PARTICIPANT_DASHBOARD,
    type: 'link',
    active: false,
    disabled: true
  },
  {
    title: TIMEPOINT(TIMEPOINT_KEYS.MONTH_24).name,
    icon: Home,
    path: ROUTES.PARTICIPANT_DASHBOARD,
    type: 'link',
    active: false,
    disabled: true
  }
]
