// eslint-disable-next-line import/prefer-default-export
export const USER_MSG = {
  LOGIN_SUCCESS: 'You have successfully logged in to the system',
  LOGIN_FAILED:
    'We could not authenticate you! Please try another username or password.',
  USER_FAILED: 'User associated with this username and password was not found'
}

export const API_MSG = {
  CHANGE_SAVED: 'Change saved',
  CHANGE_NOT_SAVED: 'Change not saved',
  DATA_NOT_AVAILABLE: 'Data is not available',
  DATA_SAVED: 'Data saved',
  INVALID_OPERATION: 'Server error',
  JOB_COMPLETE: 'Job completed, page is about to reload',
  RESOURCE_CONFLICT: 'Resource already exists',
  SERVER_INTERNAL_ERROR: 'Server internal error, please again try later',
  RESCHEDULE_STARTS: 'Auto plan starts',
  UNAUTHENTICATED: 'Action required user login',
  UNAUTHORIZED: 'Permission denied',
  UNKNOWN_ERROR: 'An unknown error occurred, please try later',
  USERNAME_PASSWORD_MISMATCH: 'Username or password is incorrect',
  VALIDATION_STARTS: 'Validation starts',
  FORM_SUBMITTED_SUCCESS: 'Form submitted successfully'
}

export const BANNER_DEFAULTS = {
  EMPTY: 'No data',
  ERROR: 'Oops! This component has crashed!',
  LOADING: 'Loading ...',
  UNKNOWN_STATE: 'INIT or unknown view state!\nIs this component initialized?'
}

export const PARTICIPANT_MSG = {
  CREATE_SUCCESS: 'Participant created successfully',
  CREATE_FAILED: 'Participant creation failed',
  UPDATE_SUCCESS: 'Participant updated successfully',
  UPDATE_FAILED: 'Participant update failed',
  VIEW_FAILED: 'Participant details fetching failed',
  ASSIGN_FAILED: 'Participant assignment to the group failed',
  ASSIGN_SUCCESS: 'Participant assigned to the group successfully',
  UPDATE_PHASE_SUCCESS: 'Phase updated successfully',
  LIST_FAILED: 'Participant list fetch failed',
  INTERVENTION_ASSIGNMENT_SUCCESS: 'Intervention access enabled successfully ',
  MARK_PHONE_CALL_MADE_SUCCESS: 'Phone call marked as complete successfully ',
  DISABLE_INTERVENTION_SUCCESS: 'Intervention access disabled successfully '
}
export const CLINICIAN_MSG = {
  LIST_FAILED: 'Participant list fetch failed'
}
