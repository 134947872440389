/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import { loadingHOC } from 'services/loadingHOC'
import PropTypes from 'prop-types'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import {
  PHASE_STATUS,
  PHASES,
  PROGRESS,
  ROUTES,
  TIMEPOINT
} from 'constants/consts'
import CompleteIcon from 'components/icons/CompleteIcon'
import { IconWrapper24 } from 'components/icons/styles'
import PageSection from 'components/PageSection'
import PageHeader from 'components/PageHeader'
import PageTitle from 'components/PageTitle'
import { useDispatch, useSelector } from 'react-redux'
import { filter, find, isEmpty, map } from 'lodash'
import fetchQuestionnaires from 'views/dashboard/participant/DashboardView/Tabs'
import { ArrowIosDownwardOutline } from '@styled-icons/evaicons-outline/ArrowIosDownwardOutline'

import questionnaireAction from 'store/actions/questionnaireActions'
import {
  getQuestionnaireByName,
  getQuestionnairesStatusByPatientID
} from 'services/api'
import {
  DashboardButton,
  DashboardCard,
  DashboardCardBody,
  DashboardCardHeader,
  DashboardCardToggle
} from 'components/Dashboard'
import {
  generateSchema,
  groupQuestions,
  orderQuestions,
  orderSections
} from 'services/questionnaire'
import ChildrenSelect from 'components/forms/ChildrenSelect'
import Modal from 'react-bootstrap/Modal'
import { PhaseCard, PhaseLink } from 'components/Phase'
import { useNavigate } from 'react-router'
import { ArrowIosUpwardOutline } from '@styled-icons/evaicons-outline'
import adminActions from 'store/actions/adminActions'

const Dashboard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userData = useSelector(state => state.user.data)
  const [openChildSelectModal, setOpenChildSelectModal] = useState(false)
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState({})
  const [phases, setPhases] = useState(PHASES)
  const [refreshQuestionnaireStatus, setRefreshQuestionnaireStatus] = useState(false);
  const [questionnaires, setQuestionnaires] = useState(
    fetchQuestionnaires(userData.timepoint, userData.patientIds.length)
  )
  const [userProgress, setUserProgress] = useState([]);
  const [interventions, setInterventions] = useState({})
  let tedipremId = useSelector(state => state.user.data.primaryId.patientId)
  const description = TIMEPOINT(userData.timepoint, userData.patientIds.length)
    .descriptionText

  const setPhaseStatus = () => {
    const phasesClone = [...phases]
    map(phasesClone, item => {
      if (userData.phase === 'PHASE_1') {
        if (item.no === 1) item.status = PHASE_STATUS.ENABLED
        else item.status = PHASE_STATUS.NO_ACCESS
      }
      if (userData.phase === 'PHASE_2') {
        if (item.no === 1) item.status = PHASE_STATUS.GREY_OUT
        if (item.no === 2) item.status = PHASE_STATUS.ENABLED
        if (item.no === 3) item.status = PHASE_STATUS.NO_ACCESS
      }
      if (userData.phase === 'PHASE_3') {
        if (item.no === 1) item.status = PHASE_STATUS.GREY_OUT
        if (item.no === 2) item.status = PHASE_STATUS.GREY_OUT
        if (item.no === 3) item.status = PHASE_STATUS.ENABLED
      }
    })
    setPhases(phasesClone)
  }

  useEffect(() => {
    setPhaseStatus()
  }, [userData.phase])

  useEffect(() => {
    dispatch(
      adminActions.fetchInterventionsStatus(
        userData.username,
        null, // Fetch all interventions, not just those for the current phase
        null // Fetch all educational interventions
      )
    ).then(statuses => {
      let list = []
      const { interventionMap, interventionStatuses } = statuses
      map(interventionMap, value => {
        const item = find(interventionStatuses, {
          interventionId: value.interventionId
        })
        if (item)
          list = [...list, value]
      })
      setInterventions(list)
    })
  }, [userData.username])

  useEffect(() => {
    let userProgressClone = []

    const promises = map(userData.patientIds, item => {
      return getQuestionnairesStatusByPatientID(item.patientId).then(result => {
        userProgressClone = userProgressClone.concat(result)
      })
    })

    Promise.all(promises).then(() => {
      setUserProgress(userProgressClone)
    })
  }, [userData.patientIds, refreshQuestionnaireStatus])

  const getAvailableQuestionnaires = () => {

    let questionnairesClone = [...questionnaires]

    map(questionnairesClone, questionnaire => {
      questionnaire.collapsed = true
      const completedItems = filter(
        userProgress,
        progress =>
          progress.questionnaireName === questionnaire.name &&
          progress.status === PROGRESS.COMPLETED.value &&
          progress.timepoint ===
          TIMEPOINT(userData.timepoint, userData.patientIds.length).name
      )
      if (!isEmpty(completedItems)) {
        if (!questionnaire.perChild) {
          questionnaire.isCompleted = false
        } else if (
          questionnaire.perChild &&
          completedItems.length === userData.patientIds.length
        ) {
          questionnaire.isCompleted = false
        }
      }
    })

    // TODO I don't think multiple-child questionnaires are handled

    // Accept only questionnaires that are available to the user and are available for completion
    const questionnairesToDisplay = filter(questionnairesClone, function(o) {
      const viewableQuestionnaire = userProgress.find(progress => {
        return progress.questionnaireName === o.name &&
          progress.status === PROGRESS.INCOMPLETE.value &&
          progress.timepoint === userData.timepoint;
      })

      return viewableQuestionnaire;
    });

    return questionnairesToDisplay;
  }

  const openSelectChildModal = () => {
    setOpenChildSelectModal(true)
  }

  const closeSelectChildModal = () => {
    setOpenChildSelectModal(false)
  }

  const handlePhaseClick = (phase, disabled) => {
    navigate(ROUTES.INTERVENTIONS, { state: { phase, disabled } })
  }

  const questionnaireCompletionCallback
    = () => {
    // Used by the questionnaire modal (via a dispatch) to notify this component
    // that a questionnaire has been submitted, and that we should re-fetch the questionnaire status from the backend
    setRefreshQuestionnaireStatus(!refreshQuestionnaireStatus);
  }

  const displayForm = async quest => {
    if (!quest) quest = selectedQuestionnaire
    const { name } = quest
    const schemas = []
    const steps = []
    let schema = {}

    const result = await getQuestionnaireByName(name)
    const sections = groupQuestions(result)
    const orderedSections = orderSections(sections)
    map(orderedSections, sectionParams => {
      const orderedQuestions = orderQuestions(sectionParams)
      schema = generateSchema(name, orderedQuestions, userData.timepoint)
      schemas.push(schema)
    })

    const lastPage = {
      description:
        'All steps completed. Thanks for participating in this questionnaire! Please click the Submit button to finalise the form.',
      type: 'object',
      properties: {
        secret: {
          type: 'string',
          default: "I'm a hidden string."
        }
      }
    }
    const lastPageUiSchema = {
      secret: {
        'ui:widget': 'hidden'
      }
    }
    schemas.push({
      step: schemas.length.toString(),
      schema: lastPage,
      uiSchema: lastPageUiSchema
    })

    dispatch(
      questionnaireAction.show(quest, result, schemas, steps, tedipremId, questionnaireCompletionCallback)
    )
  }

  const handleSelectChild = formData => {
    if (formData) tedipremId = formData.child
    setOpenChildSelectModal(false)
    displayForm()
  }

  const toggleAccordion = tabId => {
    const foundItem = find(questionnaires, { id: tabId })
    foundItem.collapsed = !foundItem.collapsed
    setQuestionnaires([...questionnaires])
  }

  const handleStart = (questionnaire, e) => {
    e.stopPropagation()
    setSelectedQuestionnaire(questionnaire)
    if (questionnaire.perChild && userData.patientIds.length > 1) {
      openSelectChildModal()
    } else {
      displayForm(questionnaire)
    }
  }

  return (
    <Container fluid className="pl-4">
      <PageHeader>
        <Col>
          <PageTitle>PARTICIPANT DASHBOARD</PageTitle>
        </Col>
      </PageHeader>
      <PageSection>
        <Col>
          <div>{description}</div>
        </Col>
      </PageSection>
      <PageSection>
        <Col>
          {getAvailableQuestionnaires().length ? (getAvailableQuestionnaires().map(tab => (
            <Accordion key={tab.id}>
              <DashboardCard>
                <DashboardCardHeader
                  onClick={() => toggleAccordion(tab.id)}
                  iscompleted={tab.isCompleted.toString()}
                >
                  <Accordion.Toggle as={DashboardCardToggle} eventKey={tab.id}>
                    {tab.collapsed ? (
                      <ArrowIosDownwardOutline
                        size={20}
                        className="mr-2"
                        color="grey"
                      />
                    ) : (
                      <ArrowIosUpwardOutline
                        size={20}
                        className="mr-2"
                        color="grey"
                      />
                    )}
                    {tab.title}
                    {tab.isCompleted ? (
                      <>
                        <ReactTooltip id="completed" place="top" effect="float">
                          <span>{PROGRESS.COMPLETED.label}</span>
                        </ReactTooltip>
                        <IconWrapper24 className="float-right">
                          <CompleteIcon
                            data-tip
                            data-for="completed"
                            iscompleted={tab.isCompleted.toString()}
                          />
                        </IconWrapper24>
                      </>
                    ) : (
                      <DashboardButton
                        className="float-right"
                        onClick={e => handleStart(tab, e)}
                      >
                        START
                      </DashboardButton>
                    )}
                  </Accordion.Toggle>
                </DashboardCardHeader>
                <Accordion.Collapse eventKey={tab.id}>
                  <DashboardCardBody>{tab.description}</DashboardCardBody>
                </Accordion.Collapse>
              </DashboardCard>
            </Accordion>
          ))) : (
            <p>There are no questionnaires available at this time. Please check again soon.</p>
          )}
        </Col>
      </PageSection>
      <Row>
        {interventions.length ? (phases.map(phase => (
          <PhaseLink
            key={phase.value}
            status={phase.status}
            onClick={() =>
              handlePhaseClick(phase.no, userData.phase !== phase.value)
            }
          >
            <PhaseCard status={phase.status}>
              <img src={phase.img} alt="" />
            </PhaseCard>
          </PhaseLink>
        ))) : null}
      </Row>
      <Modal
        show={openChildSelectModal}
        dialogAs="div"
        onHide={closeSelectChildModal}
      >
        <ChildrenSelect onStart={handleSelectChild} />
      </Modal>
    </Container>
  )
}

Dashboard.propTypes = {
  viewStateManager: PropTypes.shape({
    setLoading: PropTypes.func,
    setReadyDelayed: PropTypes.func
  })
}
export default loadingHOC(Dashboard)
